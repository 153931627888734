@import 'colors';

// Este é o arquivo para as VARIÁVEIS
//
// Tips:
// - Para estilos basicos vá em: BASE.SCSS
// - Para animações, vá em ANIMATION.SCSS
// - Para componentes externos, vá em EXTERNAL-COMPONENTES.SCSS
// FONT-SIZES
$basic-size: 16px;
// UPDATE ANGULAR
$basic-font: 'Roboto', sans-serif;
//FLAT UI COLORS:
$cse-orange:#ee7546;
$cse-lightgrey: #f7f8f8;
$cse-bg: #ecf0f1;
$note-yellow: #fffde7;
$basic-grey: #999;
$cse-grey: #d3d3d3;
$cse-blue: #3498db;
$cse-lightblue: #EDF9FF;
$cse-green: #1abc9c;
$cse-error: #EC644B;
$cse-mediungrey: #ACAFB0;
$cse-darkgrey: #23262c;
$cse-darkergrey: #1a1c1f;
$cse-bluegrey: #b7c2d1;
$cse-yellow: #F3C13A;
$cse-red: #c0392b;
$facebook: #3b5998;
$tiktok: #000;
$youtube: #cd201f;
$instagram: #e1306c;
$linkedin: #0077b5;
$googleplus: #dd4b39;
$medium: #00ab6c;
$cse-purple: #a178d1;
//MATERIAL DEISGN SHADOWS HELPER
$bs-level-1: 0 1px 3px rgba(0, 0, 0, 0.12),
0 1px 2px rgba(0, 0, 0, 0.24);
$bs-level-1-inverted: 0 -2px 3px rgba(0, 0, 0, 0.1),
0 -2px 2px rgba(0, 0, 0, 0.1);
$bs-level-2: 0 3px 6px rgba(0, 0, 0, 0.16),
0 3px 6px rgba(0, 0, 0, 0.23);
$bs-level-3: 0 10px 20px rgba(0, 0, 0, 0.19),
0 6px 6px rgba(0, 0, 0, 0.23);
$bs-level-4: 0 14px 28px rgba(0, 0, 0, 0.25),
0 10px 10px rgba(0, 0, 0, 0.22);
$bs-level-5: 0 19px 38px rgba(0, 0, 0, 0.30),
0 15px 12px rgba(0, 0, 0, 0.22);
$bs-level-1-b: 0 1px 3px rgba(0, 0, 0, 0.12),
0 1px 2px rgba(0, 0, 0, 0.1);
// FONT PATH
$fa-font-path: "../../../node_modules/font-awesome/fonts";
// mixins
@mixin box-shadow-none {
    box-shadow: none;
    -ms-box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

@mixin truncate {
    -webkit-white-space: nowrap;
    -moz-white-space: nowrap;
    white-space: nowrap;
    -webkit-overflow: hidden;
    -moz-overflow: hidden;
    overflow: hidden;
    -webkit-text-overflow: ellipsis;
    -moz-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

@mixin spin {
    animation-name: spin;
    animation-duration: 400ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: spin;
    -webkit-animation-duration: 400ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 400ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 400ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-transition: rotate(3600deg);
}

@mixin appearing-box {
    animation-name: appearing2;
    animation-duration: 2s;
    animation-iteration-count: 1;
}

@mixin marked {
    animation: appearing2 2s linear 1s, marked 1s linear 3s, border-marked 6s;
    animation-iteration-count: 1;
}

@mixin display-inline-top {
    display: inline-block;
    vertical-align: top;
}

@mixin display-inline-middle {
    display: inline-block;
    vertical-align: middle;
}


// temporary variables

$screen-xs:                  480px !default;
$screen-xs-min:              $screen-xs !default;
$screen-phone:               $screen-xs-min !default;

$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;

$screen-tablet:              $screen-sm-min !default;

$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;

$screen-desktop:             $screen-md-min !default;

$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
$screen-lg-desktop:          $screen-lg-min !default;

$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;
