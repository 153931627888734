// SOME QUICK HELPERS
.ime-text-orange {
  color: $cse-orange;
}

.ime-bg-orange {
  background-color: $cse-orange;
}

.ime-bg-instagram {
  background-color: $instagram;
}

.ime-bg-youtube {
  background: $youtube;
}

.ime-bg-tiktok {
  background: $tiktok;
}

.bold {
    font-weight: 600;
}

.fright {
    float: right;
}

.fright.ghost {
    position: absolute;
    margin-left: 90%;
}

.fleft {
    float: left;
}

.pd0 {
    padding: 0;
}

.pd010 {
    padding: 0 10px
}

.pd10 {
    padding: 10px;
}

.pdl0 {
    padding-left: 0;
}

.pdr0 {
    padding-right: 0;
}

.pdl5 {
    padding-left: 5px;
}

.pdl25 {
    padding-left: 25px;
}

.pdr5 {
    padding-right: 5px;
}

.pdlr30 {
    padding-left: 30px;
    padding-right: 30px;
}

.pb20 {
    padding-bottom: 20px;
}

.pb10 {
    padding-bottom: 10px;
}

.pt5 {
    padding-top: 5px;
}

.pt30 {
    padding-top: 30px;
}

.pt20 {
    padding-top: 20px;
}

.pt10 {
    padding-top: 10px;
}

.mg0 {
    margin: 0;
}

.mg40 {
    margin: 40px;
}

.mg10n {
    margin: 0 -10px;
}

.mb0 {
    margin-bottom: 0;
}

.mb5 {
    margin-bottom: 5px;
}

.mb10 {
    margin-bottom: 10px;
}


// UPDATE ANGULAR
.mb90 {
    margin-bottom: 90px;
}

.mt5 {
    margin-top: 5px;
}

.mt10 {
    margin-top: 10px;
}

.mt15 {
    margin-top: 15px;
}

.mt20 {
    margin-top: 20px;
}

.mt30 {
    margin-top: 30px;
}

.mb15 {
    margin-bottom: 15px
}

.mb20 {
    margin-bottom: 20px;
}

.mb30 {
    margin-bottom: 30px;
}

.mb40 {
    margin-bottom: 40px;
}

.ml0 {
    margin-left: 0 !important
}

.ml5 {
    margin-left: 5px;
}

.ml10 {
    margin-left: 10px;
}

.ml50 {
    margin-left: 50px;
}

.mr2 {
    margin-right: 5px;
}

.mr5 {
    margin-right: 2%;
}

.mr10 {
    margin-right: 10px;
}

.mr20 {
    margin-right: 20px;
}

.mrl-15 {
    margin: 0 -15px;
}

.h100 {
    height: 80vh;
}

.w0 {
    width: 0px;
}

.w90 {
  width: 90%;
}

.w100 {
    width: 100%;
}

.w200 {
    width: 200px;
}

.bdr1 {
    border-right: 1px solid $cse-grey;
}

.br0 {
    border-radius: 0 !important;
}

.border-bottom {
    border-bottom: 1px solid $cse-grey;
}

.border-top {
    border-top: 1px solid $cse-grey;
}

.big-icon {
    font-size: 120px;
}

.hr2 {
    border-top: 1px solid #ccc;
}

.this-is-a-link {
    cursor: pointer
}

.display-inlineblock {
    display: inline-block;
}

.ovx-sc{
    overflow-x: scroll;
}

.medium-grey {
    color: $basic-grey;
    font-weight: 500;
    margin-left: 5px;
}

.mh50 {
    display: block;
    content: " ";
    min-height: 50px;
}

.disabled-div {
  pointer-events: none;
}

.disabled {
    opacity: 0.5;
    .btn {
        cursor: default;
    }
}

.disabled-more {
    opacity: 0.3;
}

.tiny,
.tiny span {
    font-size: 12px;
}

.divisor {
    margin: 30px 0;
    display: block;
}

.thumbnail {
    line-height: 1.2em;
}
.fa.link{
    color: $cse-blue;
    cursor: pointer;
    font-size: 14px;
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.flex-center{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

}

.vhmobil {
    min-height: calc(100vh - 320px);
}

.c-pointer {
    cursor: pointer;
}

.align-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}
