// Este é o arquivo para ANIMAÇÕES
//
// Tips:
// - Para estilos basicos vá em: BASE.SCSS
// - Para palavras chaves, vá em VARIABLES.SCSS
// - Para componentes externos, vá em EXTERNAL-COMPONENTES.SCSS
@keyframes open-textarea {
    0% {
        height: 0;
    }
    100% {
        height: 50px;
    }
}

@-webkit-keyframes appearing {
    from {
        top: -100%;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}

@keyframes appearing {
    from {
        top: -100%;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}

@-webkit-keyframes appearing2 {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes appearing2 {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes anim-ladder {
    0% {
        visibility:hidden;
    }
    50% {
        visibility:hidden;
    }
    100% {
        visibility:visible;
    }
}

@keyframes anim-ladder {
    0% {
        visibility:hidden;
    }
    50% {
        visibility:hidden;
    }
    100% {
        visibility:visible;
    }
}

@-webkit-keyframes appearing3 {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes appearing3 {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes marked {
    0% {
        background-color: #fff;
    }
    20% {
        background-color: $cse-lightblue;
    }
    40% {
        background-color: #fff;
    }
    60% {
        background-color: $cse-lightblue;
    }
    100% {
        background-color: #fff;
    }
}

@keyframes marked {
    0% {
        background-color: #fff;
    }
    20% {
        background-color: $cse-lightblue;
    }
    40% {
        background-color: #fff;
    }
    60% {
        background-color: $cse-lightblue;
    }
    100% {
        background-color: #fff;
    }
}

@-webkit-keyframes border-marked {
    0% {
        border: 1px solid rgba(52, 152, 219, 1);
    }
    80% {
        border: 1px solid rgba(52, 152, 219, 1);
    }
    100% {
        border: 1px solid rgba(52, 152, 219, 0);
    }
}

@keyframes border-marked {
    0% {
        border: 1px solid rgba(52, 152, 219, 1);
    }
    80% {
        border: 1px solid rgba(52, 152, 219, 1);
    }
    100% {
        border: 1px solid rgba(52, 152, 219, 0);
    }
}

@-webkit-keyframes leaving {
    from {
        opacity: .7;
    }
    to {
        opacity: 0;
    }
}

@keyframes leaving {
    from {
        opacity: .7;
    }
    to {
        opacity: 0;
    }
}

@-webkit-keyframes arriving {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.7;
    }
}

@keyframes arriving {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.7;
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        -webkit-transform: scale(1) rotate(0deg);
        transform: scale(1) rotate(0deg);
    }
    to {
        -webkit-transform: scale(1) rotate(360deg);
        transform: scale(1) rotate(360deg);
    }
}

@-webkit-keyframes opacity-loop {
    0% {
        opacity: 0.1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 0.1;
    }
}

@keyframes opacity-loop {
    0% {
        opacity: 0.1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 0.1;
    }
}

@-webkit-keyframes opacity-loop2 {
    0% {
        opacity: 0.3;
    }
    50% {
        opacity: 0.7;
    }
    100% {
        opacity: 0.3;
    }
}

@keyframes opacity-loop2 {
    0% {
        opacity: 0.3;
    }
    50% {
        opacity: 0.7;
    }
    100% {
        opacity: 0.3;
    }
}

@-webkit-keyframes sk-four-bounce {
    0%,
    80%,
    100% {
        /*-webkit-transform: scale(0);
        transform: scale(0);*/
        opacity: 0;
    }
    40% {
        /*-webkit-transform: scale(1);
        transform: scale(1);*/
        opacity: 1;
    }
}

@keyframes sk-four-bounce {
    0%,
    80%,
    100% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0)
    }
    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

@-webkit-keyframes breve {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes breve {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes breve2 {
    0% {
        opacity: 1;
    }
    30% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes breve2 {
    0% {
        opacity: 1;
    }
    30% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes grow-border {
    0% {
        -webkit-box-shadow: 0px 0px 0px 4px rgba($cse-green, 0);
        -moz-box-shadow: 0px 0px 0px 4px rgba($cse-green, 0);
        box-shadow: 0px 0px 0px 4px rgba($cse-green, 0);
    }

    100% {
        -webkit-box-shadow: 0px 0px 0px 4px rgba($cse-green, 1);
        -moz-box-shadow: 0px 0px 0px 4px rgba($cse-green, 1);
        box-shadow: 0px 0px 0px 4px rgba($cse-green, 1);
    }
}

@keyframes grow-border {
    0% {
        -webkit-box-shadow: 0px 0px 0px 4px rgba($cse-green, 0);
        -moz-box-shadow: 0px 0px 0px 4px rgba($cse-green, 0);
        box-shadow: 0px 0px 0px 4px rgba($cse-green, 0);
    }

    100% {
        -webkit-box-shadow: 0px 0px 0px 4px rgba($cse-green, 1);
        -moz-box-shadow: 0px 0px 0px 4px rgba($cse-green, 1);
        box-shadow: 0px 0px 0px 4px rgba($cse-green, 1);
    }
}
