// Este é o arquivo para os ESTILOS DE CAMPANHA
//
// Tips:
// - Para estilos basicos vá em: BASE.SCSS
// - Para palavras chaves, vá em VARIABLES.SCSS
// - Para animações, vá em ANIMATION.SCSS
// - Para componentes externos, vá em EXTERNAL-COMPONENTES.SCSS
//
//
//

// PRIMENG 11
.adv-filters {
  .p-dropdown,
  .p-multiselect {
    height: 100%;
    display: flex;
    align-items: center;
    border: none;
    background: transparent;

    .p-dropdown-label.p-placeholder,
    .p-multiselect-label.p-placeholder {
      padding-left: 0;
    }

    .p-dropdown-trigger-icon,
    .p-multiselect-trigger-icon {
      color: #3498db;
      font-size: 10px;
    }
  }

  .p-slider {
    margin-top: 10px;
  }
}

.filtro-descoberta {
  margin: 10px 0;
}

.adv-filters {
  display: flex;
  flex-wrap: wrap;
  color: $basic-grey;
  margin-bottom: 10px;
  /*** Zerando e padronizando os estilos dos componentes do filtro ***/
  label,
    // button,
    select,
    textarea,
    #search,
    .dropdown-toggle,
    .ui-dropdown,
    .ui-dropdown-label,
    .ui-dropdown-trigger,
    .ui-multiselect,
    .ui-multiselect-label,
    .ui-multiselect-trigger {
    background-color: transparent !important;
    color: $basic-grey;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-size: 14px;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0;
    &:hover {
      background-color: transparent !important;
    }
    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #6c757d;
      font-style: normal;
    }
  }
  .card {
    text-align: left;
    min-height: unset;
    border-radius: 15px;
    box-shadow: none;
    flex-grow: 1;
    border: none;
  }
  .adv-filter-section {
    display: flex;
    flex-direction: column;
  }
  .ui-clickable {
    margin: 0 !important;
  }
  .ui-dropdown-item,
  .ui-multiselect-item {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: $basic-grey;
    &:hover,
    &:focus {
      label,
      span {
        color: $cse-blue !important;
      }
    }
  }
  .ui-helper-hidden-accessible {
    position: absolute;
    left: -10000em;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
  .ui-multiselect-close {
    display: none;
  }
  .ui-multiselect-filter-container {
    width: 100% !important;
  }
  .ui-dropdown-trigger,
  .ui-multiselect-trigger {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    .ui-multiselect-trigger-icon,
    .ui-dropdown-trigger-icon {
      position: relative;
      top: unset;
      left: unset;
    }
  }
  /*** Estilizando os componentes do filtro ***/
  .filter-group-title {
    font-size: 14px;
    strong {
      padding: 2px 5px;
      margin-left: 4px;
      background-color: #fdfbec;
      border-radius: 15px;
      &.content {
        background-color: #f2fdec;
      }
      &.audience {
        background-color: #ecfafd;
      }
      i {
        margin-right: 5px;
      }
    }
  }
  .adv-filter-block {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    background: #fff;
    padding: 10px;
    min-height: 128px;
    border-radius: 15px;
    .adv-filter-item {
      margin: 5px 0;
      position: relative;
    }
  }
  #search {
    width: calc(100% - 20px);
    height: 100%;
  } //
  .fa-search,
  .ui-clickable,
  .pi-search {
    color: $cse-blue !important;
  }
  .fa-search,
  .pi-search {
    padding-right: 8px;
  }
  .pi-search {
    left: unset !important;
    right: 0;
  }
  .search-autocomplete,
  .ui-dropdown,
  .ui-multiselect {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    flex: 1 1 calc(100% - 40px);
    height: 100%;
    .ui-multiselect-header {
      .ui-multiselect-filter-container .fa {
        right: 0.125em;
        left: unset;
      }
      .ui-inputtext {
        padding: 0.125em 1.6em 0.125em 0.4em;
      }
    }
  }
  .ui-multiselect-item {
    &.ui-state-highlight {
      background-color: #f4f3f4 !important;
      color: $basic-grey !important;
      .ui-clickable {
        color: white !important;
        outline: none !important;
        vertical-align: unset !important;
      }
      .ui-chkbox {
        border: none !important;
      }
      &:focus {
        outline: none !important;
      }
    }
  }
  .ui-dropdown {
    width: 100% !important;
    min-width: unset !important;
    .ui-dropdown-item {
      span {
        white-space: nowrap;
      }
    }
  }
  .ui-dropdown-panel,
  .ui-multiselect-panel {
    left: -40px !important;
    min-width: calc(100% + 40px);
  }
  .ui-multiselect-items-wrapper {
    max-height: 220px;
    label {
      cursor: pointer;
    }
    .ui-multiselect-items {
      .ng-star-inserted:first-child {
        .ui-chkbox {
          display: none;
        }
      }
    }
  }
  .suggestions {
    min-width: 100%;
    max-height: 250px;
    padding: 10px 0 0;
    left: 0;
    top: 24px;
    overflow-y: scroll;
    overflow-x: hidden;
    li {
      padding: 5px 10px !important;
      a {
        white-space: nowrap;
        color: $basic-grey !important;
        font-size: 16px;
      }
      &:hover,
      &:focus {
        background-color: #f4f3f4;
        a {
          color: $cse-blue !important;
        }
      }
      &:last-child {
        margin-bottom: 10px;
      }
    }
    .sug-inf {
      margin: 0;
    }
    .avatarz {
      margin-right: 10px;
    }
    .influencer-link {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      cursor: pointer;
      &:hover,
      &:focus {
        .user-name {
          color: $cse-blue !important;
          cursor: pointer;
        }
      }
    }
    .quick-loading {
      padding: 0 10px 5px;
    }
  }
  .adv-filter-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    background-color: white;
    border: 1px solid $cse-grey;
    border-radius: 15px;
    height: 26px;
    &::before {
      font: normal normal normal 14px/1 FontAwesome;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      flex-shrink: 0;
      position: relative;
      width: 32px;
      color: $cse-grey;
      border-right: 1px solid $cse-grey;
      margin-right: 8px;
    }
    &:hover {
      &::before {
        color: $cse-blue;
      }
    }
    &.afinidade {
      display: block;
      border: 0;
      padding: 0 10px 0 5px;
      background-color: transparent;
      margin-top: 14px;
      .ui-slider {
        background-color: $basic-grey;
        border-color: $basic-grey;
      }
    }
    &.afinidade::before {
      display: none;
    }
    &.age::before {
      content: "\f1fd";
    }
    &.brands::before {
      content: "\f02b";
    }
    &.gender::before {
      content: "\f228";
    }
    &.interests::before {
      content: "\f075";
    }
    &.language::before {
      content: "\f1ab";
    }
    &.name::before {
      content: "\f2c2";
    }
    &.places::before {
      content: "\f0ac";
    }
    &.maps::before {
      content: "\f041";
    }
    &.range::before {
      content: "\f1eb";
    }
    &.social {
      border: none;
      background-color: transparent;
      .btn-social-network-group {
        -ms-flex-pack: distribute;
        justify-content: space-around;
        width: 100%;
      }
      .social-icon {
        height: 30px;
        width: 30px;
        padding-top: 0;
      }
      i {
        cursor: pointer;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        font-size: 16px;
      }
      .social-btn-inactive {
        i {
          background-color: $basic-grey;
        }
      }
    }
    .ng-valid {
      -ms-flex-item-align: center;
      align-self: center;
      width: 100%;
      height: 100%;
    }
  }
}

.adv-filters {
  .slider-group {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    .adv-filter-item:nth-child(2) {
      padding-bottom: 32px;
    }
  }
}

.adv-filter-tooltip {
  .ui-tooltip-arrow {
    color: $cse-blue;
    border-top-color: $cse-blue;
  }
  .ui-tooltip-text {
    background-color: $cse-blue !important;
  }
}

/*** FILTRO MOBILE ***/

.filter-advanced-mobile {
  .adv-filters {
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    h5 {
      position: relative;
      display: block;
      margin-top: 30px;
      padding: 0;
    }
    .adv-filter-menu {
      display: none;
    }
    .adv-filter-header {
      .adv-filter-item {
        min-width: unset;
        height: 32px;
        margin-bottom: 0;
        margin-right: 10px;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
      }
      .btn-primary {
        width: 38px;
      }
    }
    .adv-filter-item:not(.name) {
      display: flex;
      position: relative;
      min-width: 100%;
      height: 32px;
      margin-bottom: 20px;
    }
    .ui-dropdown-item span,
    .suggestions li a {
      white-space: normal;
    }
    .ui-dropdown-panel,
    .ui-multiselect-panel {
      max-width: 100%;
    }
  }
}

.show-filter {
  .filter-advanced-mobile {
    .adv-filters {
      .adv-filter-header {
        .fa-filter::before {
          content: "\f00c";
        }
      }
      .adv-filter-menu {
        display: block;
      }
    }
  }
  .hide-on-filter {
    display: none;
  }
  .filter-tags-mobile {
    position: fixed;
    bottom: 0;
    background: $cse-bg;
    width: 100%;
    -webkit-box-shadow: 0px -2px 4.9px 0.1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px -2px 4.9px 0.1px rgba(0, 0, 0, 0.1);
    z-index: 6;
    #filter-tags {
      margin: 5px 0;
    }
  }
}
// UPDATE ANGULAR
#grid {
  .ui-multiselect {
    span {
      font-size: 16px;
      color: #999;
      font-weight: normal;
    }
    .ui-multiselect-label {
      padding-top: 0;
      padding-left: 0;
      padding-bottom: 0;
      margin: 0;
    }
  }
  .non-first-chk {
    .ui-multiselect-items {
      p-multiselectitem:first-child {
        .ui-chkbox {
          display: none !important;
        }
      }
    }
  }
  .ui-chkbox-box.ui-state-active {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ui-multiselect-item label {
    margin-bottom: 0;
  }
  .adv-filter-item.social {
    border: none;
    background-color: transparent;
    .btn-social-network-group {
      -ms-flex-pack: distribute;
      justify-content: space-around;
      width: 100%;
    }
    .social-icon {
      height: 25px;
      width: 25px;
      padding-top: 0;
    }
    i {
      cursor: pointer;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      font-size: 14px;
    }
    .social-btn-inactive {
      i {
        background-color: $basic-grey;
      }
    }
  }
  .drop-curator {
    min-width: unset;
    .ui-dropdown-label {
      color: $basic-grey;
      padding: 2px 5px;
    }
    span.ui-clickable.fa-caret-down {
      margin-top: 3px;
    }
  }
  .drop-lastdate .ui-inputtext {
    padding: 2px 5px;
    width: 100%;
  }
}

/*** MEDIA QUERIES ***/

@media only screen and (max-width: 1360px) {
  .adv-filters {
    .adv-filter-block {
      min-height: 50px;
    }
  }
}

@media only screen and (max-width: $screen-md-max) {
  .filter-advanced {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: $screen-sm-max) {
  .adv-filters {
    .kind-of-filter {
      width: 100%;
    }
    .ui-dropdown {
      .ui-dropdown-item {
        span {
          white-space: normal;
        }
      }
    }
  }
}

@media only screen and (max-width: $screen-xs-max) {
  .filtro-descoberta {
    margin-top: 0;
  }
  .adv-filters {
    .suggestions {
      top: 30px;
    }
    .ui-multiselect {
      .ui-multiselect-items-wrapper {
        overflow-x: hidden;
      }
    }
  }
}
