// Este é o arquivo para o HEADER
//
// Tips:
// - Para estilos basicos vá em: BASE.SCSS
// - Para palavras chaves, vá em VARIABLES.SCSS
// - Para animações, vá em ANIMATION.SCSS
// - Para componentes externos, vá em EXTERNAL-COMPONENTES.SCSS
#cheader {
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 3px 4.9px 0.1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 4.9px 0.1px rgba(0, 0, 0, 0.1);
  width: 100%;
  border: 0;

  .navbar-brand {
    img {
      margin-top: -10px;
      width: auto;
      height: 25px;
      max-width: 100%;
      &:hover {
        opacity: 0.5;
      }
    }
  }

  .profile-header {
    .user-header {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      color: $basic-grey;
      margin-right: 10px;
      line-height: 18px;
      .user-name {
        text-transform: capitalize;
        margin: 0 0 0 5px;
        font-size: 14px;
      }
    }
    a {
      font-size: 11px;
      color: $cse-blue;
    }
    i {
      cursor: pointer;
    }
  }

  .navbar-nav {
    .nav-item {
      .nav-link {
        color: $cse-blue;
        opacity: 1;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        &:hover {
          opacity: 0.5;
        }
        &:focus {
          color: inherit;
        }
        &:hover,
        &:active,
        &:visited,
        &:link {
          text-decoration: none;
        }
      }

      &.active .nav-link {
        color: $cse-orange;
      }
    }
  }
}

// MOBILE STYLES ----------------------------------------------------------------------------------------------------------------------------
@media only screen and (min-width: 991.99px) {
  #cheader .navbar-nav {
    gap: 8px;
    .nav-item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px;
      border-radius: 2px;
      width: 35px;
      height: 35px;
      text-align: center;
      background-color: $cse-blue;
      .nav-link {
        color: #fff;
        font-size: 18px;
        span {
          display: none;
        }
        &:focus {
          color: white;
        }
      }

      &.active {
        background-color: $cse-orange;
        .nav-link {
          color: #fff;
        }
      }
    }
  }
}

//479px & lower
@media (max-width: 991.99px) {
  .profile-header {
    display: none !important;
  }
}
