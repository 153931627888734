// PANEL DA FICHA DO INFLUENCIADOR
.last-update {
    display: block;
    padding: 2px 5px;
    border-radius: 4px;
    background: $note-yellow;
    font-size: 11px;
    margin-top: 10px;
}

.badge-warning {
    background: #f0ad4e;
}

.social-network-container {
    margin: 5px 10px;
    display: inline-block;
    width: calc(100% - 10px);
    .js-tag{
        &:before{ display: none; }
        &:after{ display: none; }
        .social-network-profile i.social-icon{ color: #fff; }
    }
    .sn-tag {
        background: white;
        padding: 4px;
        border: 1px solid $cse-grey;
        display: inline-block;
        .link-to-social,
        .btn-remove {
            font-size: 14px;
            margin-right: 8px;
            cursor: pointer;
            i {
                color: $cse-blue;
            }
        }
    }
    .sn-wiki {
        background-color: transparent;
        display: inline-block;
        min-width: 410px;
        height: 31px;
        margin-right: 10px;
        input {
            height: 32px;
        }
    }
    .plus-social,
    .plus-social:hover {
        .caret {
            color: white;
        }

    }
}

.update-profile {
    margin: 6px 0;
    background: transparent;
    padding: 0px 10px;
    border-radius: 20px;
    display: inline-block;
}

span.last-update-profile {
    font-style: italic;
    font-size: 14px !important;
}

.v-top {
    vertical-align: top;
    display: inline-block;
}

.header-ficha-cadastro h2 {
    color: $cse-grey;
    text-transform: uppercase;
    display: inline-block;
    font-size: 30px;
}

.basic-data {
    padding-top: 10px;
    textarea {
        height: 141px;
    }
    label {
        text-transform: uppercase;
        margin-bottom: 5px;
        font-size: 14px;
    }
    select.form-control,
    input.form-control {
        height: 33px;
        padding: 4px 12px;
    }
    .ui-dropdown-label {
        margin-bottom: 0;
        padding: 7px 12px;
        text-transform: none;
    }
    .ui-dropdown .ui-dropdown-trigger .fa {
        margin-top: .4em;
    }
    .contato {
        display: block;
        position: relative;
        padding: 20px;
        background-color: $cse-lightgrey;
    }
    #drop-archetype {
        .ui-dropdown-panel .ui-dropdown-item .ui-helper-clearfix div {
            float: left !important;
            margin-left: 40px;
        }
    }
}

.outros-ficha-de-cadastro h3 {
    color: $cse-grey;
    text-transform: uppercase;
    margin: 10px 0 20px;
}

.outros-ficha-de-cadastro .button-save {
    padding: 0px;
    padding-bottom: 20px;
}

span.dicas-data-set {
    font-size: 14px !important;
}

.data-set-item {
    padding: 5px;
    margin: 5px 0;
    .js-tag {
        margin: 0;
    }
    &:nth-child(odd) {
        background: $cse-lightgrey;
    }
}

//INDICES DO INFLUENCIADOR (AUDIÊNCIA E ASSUNTOS)
.indice {
    display: block;
    position: relative;
    padding: 15px;
    border: 1px solid $cse-mediungrey;
    margin-bottom: 20px;
    label {
        margin-bottom: 20px;
    }
    .indice-two-colums {
        -webkit-column-count: 2;
        /* Chrome, Safari, Opera */
        -moz-column-count: 2;
        /* Firefox */
        column-count: 2;
        -webkit-column-gap: 60px;
        /* Chrome, Safari, Opera */
        -moz-column-gap: 60px;
        /* Firefox */
        column-gap: 40px;
    }
    .indice-three-colums {
        -webkit-column-count: 3;
        /* Chrome, Safari, Opera */
        -moz-column-count: 3;
        /* Firefox */
        column-count: 3;
        -webkit-column-gap: 60px;
        /* Chrome, Safari, Opera */
        -moz-column-gap: 60px;
        /* Firefox */
        column-gap: 40px;
    }
    .indice-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-bottom: 15px;
        span {
            font-size: 14px;
            color: #666;
        }
        .line {
            -webkit-box-flex: 5;
            -ms-flex-positive: 5;
            flex-grow: 5;
            border-bottom: 1px dotted $cse-grey;
        }
    }
}

// DATASET
.dataset,
form.modal-insert-dataset {
    display: inline-block;
    width: 100%;
    .ui-widget-content{ border:none; }
}

.dataset-wrap {
    margin: 20px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.dataset-edit-new-form{
    min-height: calc(100vh - 250px);
}
.modal-insert-dataset {
    padding: 0;
    width: 100%;

    .generate-radical {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      p {
        padding-right: 24px;
      }

      button {
        flex-shrink: 0;
      }
    }

    .modal-insert-dataset-content {
        display: block;
    }
    .btn-primary{
        padding: 12px;
        min-width: 250px;
        font-size: 28px;
        &.btn-small {
          font-size: 14px;
          min-width: unset;
          padding: 8px 12px;
        }
        &.btn-process-dataset{
            font-size: 16px;
            min-width: unset;
            background-color: #1EA97C;
        }
    }
}

.modal-insert-dataset-content {
    .adv-filters{
        &:nth-child(n+1){ width: 100%; }
        .suggestions{
            margin-top: 44px;
        }
    }
}
.influencer-lab{
    display: flex;
    justify-content: space-between;
    .btn-success{
        height:120px;
        width: calc(20% - 10px);
        i{font-size: 36px;}
    }
}
.influencer-lab-indicator{
    display: block;
    border: 1px solid #d3d3d3;
    font-size: 24px;
    padding: 12px;
    width: auto;
    text-align: center;
    color: $cse-green;
    border-bottom: 4px solid $cse-green;
    &.negative{
        color: $cse-red;
        border-bottom: 4px solid $cse-red
    }
}
ul.infuencer-list{
    border: 1px solid #f4f4f4;
    list-style-type: none;
    padding: 0;
    width:80%;
    height: 119px;
    overflow-y: scroll;
    li{
        display: flex;
        padding: 2px 5px;
        card-infuencer-in-line{
            @include truncate;
            width: 80%;
        }
        a.arq-action{
            width: 20%;
        }
    }
    li:nth-child(even){
        background: $cse-bg;

    }
    img{ display: none;}

}

.lista-keywords{
    display: block;
    height: calc(100vh - 500px);
    width: 100%;
    border-radius: 4px;
    border: 1px solid #ccc;
    overflow-y: scroll;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

#keyword {
    width: 100%;
    padding: 10px;
    .keyline{
        cursor: pointer;
        display: block;
        width: 100%;
        padding: 8px 12px;
        border-top: 1px solid #f4f4f4;
        display: flex;
        justify-content: space-between;
        &:first-child { border-top: none; }
        i{
            &.fa-trash{ color: $cse-red; cursor: pointer; }
            &.fa-plus-circle{ color: $cse-blue; cursor: pointer; }
        }
    }

}


.language-toggle {
    .ui-button-text {
        padding: .10em .8em
    }
}
.post-container{
    .ui-overlaypanel{
        top:20px !important;
        left:200px !important;
        width: 300px;
        height: 400px;
        overflow: hidden;
        overflow-y: scroll;
        ul{ padding:0;}
        li{
            list-style: none;
            padding: 5px 7px;
            &:nth-child(even){ background: #f4f4f4; }
        }
    }
}
.highlightText{
    background: yellow;
}
.panel-dataset {
    min-height: calc(100vh - 180px);
    h4 {
        text-transform: uppercase;
    }
    h5 {
        text-transform: uppercase;
        margin-bottom: 20px;
        font-size: 16px;
        margin-top: 0;
    }
    p,
    h5,
    h4 {
        color: $basic-grey;
    }
    .limpar-filtro {
        margin-top: 20px;
    }
    input.ui-inputtext.ui-widget.ui-state-default.ui-corner-all {
        padding: 6px;
        border-radius: 4px;
    }
    alert {
        position: fixed;
        width: 100%;
        top: 60px;
        left: 0;
        right: 0;
        padding: 10px;
        z-index: 99999;
    }
    .alert span {
        color: #666;
    }
}

.row.flex-box-vertical {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.row.lista-data-set {
    border: 1px solid $cse-grey;
    padding: 10px 0;
    border-radius: 4px;
}

.alert-view-only {
    //font-weight: bold;
    span,
    span i {
        color: #666 !important;
    }
}

.list-dropdown {
    padding: 2px 4px; //border-bottom: 1px solid #ccc;
}

.dropdown-menu.countries {
    max-height: 150px;
    overflow-y: scroll;
    cursor: default;
}

.dropdown-menu.social-network li{
    padding: 5px;
}

alert.top-fixed {
    position: fixed;
    width: 100%;
    top: 90px;
    left: 0;
    right: 0;
}

.panel-body.ficha-loader {
    animation-name: opacity-loop;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    .input.form-control {
        box-shadow: none;
        border: 0;
        background-color: #ccc;
        opacity: 0.7;
    }
}

.panel-footer {
    position: fixed;
    bottom: 27px;
    left: 0;
    right: 0;
    background: white;
}

// SETAS DE NAVEGAÇÃO LATERAL
.navegacao-lateral {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    a {
        background: #3498db;
        color: white;
        font-size: 30px;
        cursor: pointer;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        padding: 13px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        &:hover {
            color: white;
        }
        &.nav-anterior {
            margin-left: -50px;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
        }
        &.nav-proximo {
            margin-right: -50px;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
        }
    }
}

.panel.info-panel {
    padding: 10px !important;
    background: $note-yellow;
    margin: 0;
    box-shadow: none;
    border: 1px solid #ccc;
    border-radius: 10px;
    line-height: 14px;
    p {
        font-size: 13px !important;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

// TELEFONE ARRAY
.tel-array {
    .tel-array-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        input[type=text] {
            margin-left: 6px;
        }
        a {
            cursor: pointer;
        }
        .fa {
            font-size: 16px;
        }
        .first {
            display: none;
        }
        .remove-disabled {
            display: block;
            color: red;
            margin-left: 6px;
        }
    }
}

//  DATASET ANGULAR UPDATE
.key-words{
    textarea{
        height: 140px;
    }
}

//MODAIS DA TELA DE ARQUÉTIPO
.arq-modal {
    color: $basic-grey;
    .modal-body {
        padding: 20px;
        text-align: left;
        .arq-title {
            font-size: 16px;
            font-weight: bold;
            color: $cse-orange;
            margin-top: -15px;
        }
        .nav-tabs {
            .nav-item {
                width: 100px;
                text-align: center;
            }
            .nav-item:not(.active) {
                .nav-link {
                    background-color: #eee;
                    border-bottom-color: #ddd;
                }
            }
        }
        .tab-content {
            padding: 15px 15px 10px;
            border-bottom: 1px solid #ddd;
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
        .arq-icon {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            border-radius: 4px;
            width: 100%;
            height: 180px;
            background-color: #eee;
            padding: 10px;
            img {
                margin-top: 30px;
                width: 64px;
                height: 64px;
            }
            button {
                margin-top: auto;
            }
        }
        label {
            -ms-flex-item-align: start;
            align-self: flex-start;
        }
        .col-md-3.flex {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            align-self: stretch;
        }
        .btn-save {
            margin-top: auto;
        }
    }
}

.hashtag-search .panel.panel-default {
    .panel-heading{
        padding: 30px;
        background: #fff;
    }
    .form-control,
    .btn.btn-block,
    .ui-dropdown,
    .ui-dropdown-clearable,
    .pdropdown-component
    {
        border-radius: 20px;
        height: 40px;
        box-shadow: none;
        width: 100%;
    }
    .btn.btn-block{
        padding: 5px;
        margin-top: 30px;
    }
    .ui-dropdown,
    .ui-dropdown-label,
    .ui-dropdown-trigger{
        background: transparent;
    }
    .ui-multiselect, .ui-dropdown {
        display: flex;
        align-items: center;
    }
}

.fixed-bar{
    position: fixed;
    background: #fff;
    box-shadow: $bs-level-2;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    padding: 20px;
    z-index: 999999999999;
}
.checkbox-container{
    padding: 5px 12px;
    border-radius: 20px;
    border: 1px solid $cse-green;
    color: $cse-green;
    label{ margin: 0 10px 0 0; }
}

.radar-panel{
    .ui-tabview-nav { background: transparent !important; }
    .ui-tabview-panel{ padding: 0 !important; }
    .ui-tabview{ background-color: transparent;}
}

.search-autocomplete.location{
    .suggestions{
        left: 25px;
        top: 72px;
        max-height: 400px;
        overflow: scroll;
    }
}

.fixed-alerts{
    width: 100%;
    top: 80px;
    left: 0;
    right:0;
    position: fixed;
    z-index: 1;
}

.form-client{
    background: $cse-bg;
    padding: 20px;
    &.graphs{
        min-height: 200px;
        padding: 13px;
    }
    .graph-container{
        display: grid;
        overflow: hidden;
    }
}
.calculo{
    padding: 15px;
    span {
        line-height: 20px;
    }
    .flex{
        &:nth-child(even){
            background: rgba(255,255,255,0.5);
        }
    }

}
.form-client, .calculo{ border-radius: 8px;}

.calculo hr {
  height:0;
  border:0;
  border-top:1px solid;
}



