// Este é o arquivo para o FOOTER
//
// Tips:
// - Para estilos basicos vá em: BASE.SCSS
// - Para palavras chaves, vá em VARIABLES.SCSS
// - Para animações, vá em ANIMATION.SCSS
// - Para componentes externos, vá em EXTERNAL-COMPONENTES.SCSS
footer {
    width: 100%;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $cse-lightgrey;
    display: block;
    padding: 5px;
    font-size: 12px;
    color: #404c60;
    text-align: center;
    z-index: 5;
    span {
        margin-right: 5px;
    }
}
