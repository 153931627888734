// Este é o arquivo para PÁGINA DE BUSCA DE INFLUENCIADORES
//
// Tips:
// - Para estilos basicos vá em: BASE.SCSS
// - Para palavras chaves, vá em VARIABLES.SCSS
// - Para animações, vá em ANIMATION.SCSS
// - Para componentes externos, vá em EXTERNAL-COMPONENTES.SCSS
// OBS: o slider dde idade é um componente externo.
// BUSCA-FILTRO
.coluna-filtro {
    padding: 0 15px;
    width: 310px;
    display: inline-block;
    vertical-align: top;
}

a.link-name {
    color: $basic-grey;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        color: $cse-blue;
    }
}

.btn.genre {
    text-align: left;
    border: 1px solid #ccc;
    color: #ccc;
    &:hover {
        border: 1px solid $cse-blue;
        color: $cse-blue;
        background-color: #fff;
    }
    i {
        margin-right: 10px;
    }
    &.actived {
        border: 1px solid $cse-blue;
        color: $cse-blue;
        background-color: #fff;
    }
}

.influencers-search {
    .panel.panel-default {
        padding: 20px
    }
    #search .form-control {
        box-shadow: none;
        -webkit-box-shadow: none;
        padding: 30px 50px 30px 20px;
        border: 0;
        -webkit-box-shadow: 0;
        box-shadow: 0;
        border-radius: 0;
        background: url("/assets/img/search.png") no-repeat $cse-lightgrey;
        background-position: right center;
        width: 100%;
        display: block;
        &:focus {
            -webkit-box-shadow: 0 0 0 0;
            box-shadow: 0 0 0 0;
        }
    }
    h3 {
        text-transform: uppercase;
        font-weight: 300;
        color: #ccc;
        text-rendering: optimizeLegibility;
        margin: 30px 0 0;
        font-size: 18px;
        i {
            font-size: 18px;
            margin-right: 10px;
        }
    }
    .filter-group {
        padding: 20px 0;
    }
    .filter {
        border-top: 1px solid #ecf0f1;
        margin-top: 20px;
    }
    label {
        font-size: 14px;
    }
}

.slider.form-group {
    color: $basic-grey;
    padding: 0 5px 0 0;
}

.filter-social a {
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(1);
    -ms-filter: grayscale(1);
    -o-filter: grayscale(1);
    filter: grayscale(1);
    -webkit-filter: greyscale(1);
    filter: greyscale(1);
    opacity: .2;
    &.activated {
        -webkit-filter: grayscale(0);
        -moz-filter: grayscale(0);
        -ms-filter: grayscale(0);
        -o-filter: grayscale(0);
        filter: grayscale(0);
        -webkit-filter: greyscale(0);
        filter: greyscale(0);
        opacity: 1;
    }
}

// LISTA DE INFLUENCIADORES-------------------------------------------------------------------------------
.coluna-lista {
    width: 100%;
    padding: 0;
    display: inline-block;
    vertical-align: top;
    &.hashtag-search-body{
        padding-bottom: 90px;
    }
    .message-helper {
        width: 100%;
        text-align: center;
        opacity: 0.7;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-top: 35px;
        h2,
        h3,
        h4 {
            color: $basic-grey;
        }
    }
    .coluna-lista-itens {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        margin-right: -20px;
        .coluna-lista-item {
            margin: 0 20px 20px 0;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            &:last-child {
                margin-right: auto;
            }
            .card {
                height: 100%;
                border-radius: 15px;
                box-shadow: none;
                border: none;
            }
            .engagement-reach{
                vertical-align: middle;
                line-height: 16px;
                .social,
                .resonance{
                    border: 1px solid #D3D3D3;
                    border-radius: 4px;
                    display: inline-block;
                    padding: 2px 8px;
                    margin: 5px 2px 0;
                }
            }
            @media (max-width: 459px) {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 calc(100% - 20px);
                flex: 0 0 calc(100% - 20px);
                max-width: calc(100% - 20px);
            }
            @media (min-width: 460px) {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 calc(50% - 20px);
                flex: 0 0 calc(50% - 20px);
                max-width: calc(50% - 20px);
            }
            @media (min-width: 640px) {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 calc(33.333% - 20px);
                flex: 0 0 calc(33.333% - 20px);
                max-width: calc(33.333% - 20px);
            }
            @media (min-width: 940px) {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 calc(25% - 20px);
                flex: 0 0 calc(25% - 20px);
                max-width: calc(25% - 20px);
            }
            @media (min-width: 1360px) {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 calc(20% - 20px);
                flex: 0 0 calc(20% - 20px);
                max-width: calc(20% - 20px);
            }
            @media (min-width: 1440px) {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 calc(16.666% - 20px);
                flex: 0 0 calc(16.666% - 20px);
                max-width: calc(16.666% - 20px);
            }
            @media (min-width: 1900px) {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 calc(14.25% - 20px);
                flex: 0 0 calc(14.25% - 20px);
                max-width: calc(14.25% - 20px);
            }
        }
    } // CARD DO INFLUENCIADOR
    .media-icons-float {
        margin: 0px 4px;
    }
    a.signal-ladder {
        display: block;
        content: " ";
        cursor: pointer;
        width: 16px;
        height: 16px;
    }
    .ladder {
        display: block;
        width: 25px;
        height: 25px;
        border-radius: 2px;
        border: $cse-grey solid 1px;
        .fa {
            font-size: 16px;
        }
        a {
            cursor: pointer;
            width: 100%;
            height: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
        &::before {
            display: none;
            text-decoration: none;
            float: left;
        }
        &.Unaware:before {
            content: "Desconhecido";
        }
        &.Aware:before {
            content: "Conhecido";
        }
        &.Interested:before {
            content: "Interessado";
        }
        &.Engaged:before {
            content: "Engajado";
        }
        &.Advocate:before {
            content: "Promotor";
        }
    }
    .ladder-stage-plate {
        display: none;
        color: #fff;
        padding: 1px 3px;
        border-radius: 10px;
        position: absolute;
        margin: auto;
        bottom: 8px;
        left: 0;
        right: 0;
        text-rendering: optimizeLegibility;
        background: $cse-green;
        text-transform: capitalize;
    }
    .Unaware .ladder-stage-plate {
        display: block;
        width: 90px;
    }
    .Aware .ladder-stage-plate {
        display: block;
        width: 75px;
    }
    .Interested .ladder-stage-plate {
        display: block;
        width: 90px;
    }
    .Engaged .ladder-stage-plate {
        display: block;
        width: 70px;
    }
    .Advocate .ladder-stage-plate {
        display: block;
        width: 70px;
    }
    .ladder-stage-plate-list {
        display: none;
    }
    #avatar-container {
        margin-bottom: 5px;
        position: relative;
    }
    .Unaware .social-avatar,
    .Aware .social-avatar,
    .Interested .social-avatar,
    .Engaged .social-avatar,
    .Advocate .social-avatar {
        -webkit-box-shadow: 0px 0px 0px 4px rgba($cse-green, 0.4);
        -moz-box-shadow: 0px 0px 0px 4px rgba($cse-green, 0.4);
        box-shadow: 0px 0px 0px 4px rgba($cse-green, 0.4);
    }
    .name {
        font-size: 16px;
    }
    .name,
    span.title {
        display: block;
        width: 100%;
        @include truncate;
    }
    span.title {
        font-style: italic;
        opacity: 0.7;
    }
    .tags {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 8px 0 0;
        margin-top: auto;
        .menu-divisor {
            display: block;
            margin: 0 -10px;
            border-top: $cse-bg solid 1px;
            height: 10px;
            opacity: .8;
        }
    }
    &.exibe-lista {
        .coluna-lista-itens {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            .coluna-lista-item {
                max-width: 100%;

                &:last-child {
                  margin: 0 20px 20px 0;
                }
            }
        }
        .card {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            min-height: auto;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            #avatar-container {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                margin-bottom: 0;
                .social-avatar {
                    width: 50px;
                    height: 50px;
                    margin: 0;
                }
                .ladder-stage-plate {
                    display: none;
                }
                &.Unaware .social-avatar,
                &.Aware .social-avatar,
                &.Interested .social-avatar,
                &.Engaged .social-avatar,
                &.Advocate .social-avatar {
                    -webkit-box-shadow: 0px 0px 0px 4px $cse-green;
                    -moz-box-shadow: 0px 0px 0px 4px $cse-green;
                    box-shadow: 0px 0px 0px 4px $cse-green;
                }
            }
            .info-influencer-card {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                margin-left: 10px;
                .name {
                    width: inherit;
                }
                & > div {
                    padding: 0 10px;
                    border-right: $cse-grey 1px solid;
                }
                .engagement-reach{ margin-top: 0;}
            }
            .tags {
                flex-grow: 10;
                width: inherit;
                margin: 0 10px;
                padding: 0;
                .menu-divisor {
                    display: none;
                }
                #dts-progress-bar {
                    text-align: left;
                    .dts-card-subject {
                        display: inline-block;
                        position: relative;
                        width: 180px;
                        margin: 0 10px 0 0;
                    }
                }
            }
            .topo {
                -webkit-box-ordinal-group: 6;
                -ms-flex-order: 5;
                order: 5;
                -ms-flex-item-align: end;
                margin-left: auto;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                z-index: auto;
                .btn-add-ladder {
                    position: relative;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    z-index: auto;
                    .add-message {
                        left: auto;
                        right: 32px;
                        top: 6px;
                    }
                    .ladder-signal {
                        margin-right: 5px;
                    }
                    .add-button {
                        z-index: auto;
                    }
                }
            }
            &.spin {
                margin-bottom: 0;
                .topo,
                .ladder.fright {
                    display: block;
                    position: relative !important;
                }
                .social-avatar {
                    width: 50px;
                    height: 50px;
                    margin: 0;
                }
                .info-influencer-card {
                    width: 80%;
                    .name,
                    .social {
                        margin: 0 10px;
                    }
                }
            }
        }
        .card.new-influencer {
            .social-avatar {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                i {
                    margin-top: 0;
                    font-size: 28px;
                }
            }
            .info-influencer-card {
                margin-left: 20px;
                width: 100%;
                h5 {
                    display: inline;
                    margin: 0;
                    br {
                        display: none;
                    }
                }
                .form-group {
                    border-right: 0;
                    margin: 0 0 0 auto;
                    padding: 0;
                }
            }
        }
    }
}

.panel-body .card {
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: -20px auto;
}

.card {
    background-color: #fff;
    padding: 10px;
    -webkit-box-shadow: $bs-level-1-b;
    box-shadow: $bs-level-1-b;
    text-align: center;
    color: $basic-grey;
    font-size: 12px;
    min-height: 250px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    .topo {
        position: relative;
        .add-message {
            position: absolute;
            left: 0;
        }
        .btn-add-ladder {
            position: absolute;
            width: 100%;
            left: 0;
            right: 0;
            z-index: 1;
            .ladder-signal {
                width: 25px;
                height: 19px;
            }
        }
    }
    .dropdown-menu {
        margin-top: 0;
    }
    .social-avatar {
        width: 120px;
        height: 120px;
        display: block;
        background-color: #fff;
        margin: 15px auto;
        overflow: hidden;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;
        img {
            width: 100%;
            min-height: 100%;
            display: block;
        }
        i {
            color: $cse-blue;
            margin-top: 28px;
            font-size: 40px;
        }
    }
    .link-name {
        color: $basic-grey;
        text-decoration: none;
        cursor: pointer;
        &:hover {
            color: $cse-blue;
        }
    }
    .archetype {
        font-size: 14px;
        color: #86a3aa;
        margin-top: 5px;
        margin-bottom: 5px;
        img {
            width: 16px;
            height: 16px;
            margin-left: 6px;
        }
    }
    &.new-influencer {
        background-color: $cse-lightblue;
        h5 {
            color: $basic-grey;
            font-size: 18px;
        }
        .social-avatar {
            cursor: initial;
        }
    }
    &.spin {
        .topo,
        .name,
        .social-avatar,
        .social,
        .placeholder {
            -webkit-animation-name: opacity-loop;
            animation-name: opacity-loop;
            -webkit-animation-duration: 3s;
            animation-duration: 3s;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
        }
        .topo .ladder.fright {
            background: #ccc;
            border-radius: 50%;
            height: 20px;
            width: 20px;
            opacity: 0.5;
        }
        .info-influencer-list {
            width: calc(100% - 80px) !important;
        }
        .social-avatar {
            background: #ccc;
            opacity: 0.5;
        }
        .name {
            opacity: 0.5;
            background: #ccc;
            height: 16px;
            border-radius: 4px;
            width: 70%;
            margin: 0 auto 10px;
        }
        .social {
            opacity: 0.5;
            display: block;
            background: #ccc;
            height: 16px;
            margin: 0 auto 10px;
            border-radius: 4px;
        }
        .placeholder {
            opacity: 0.5;
            display: block;
            background: #ccc;
            height: 16px;
            margin: 0 auto 10px;
            border-radius: 4px;
            margin-right: 20px;
            &:nth-child(1) {
                width: 15%;
            }
            &:nth-child(2) {
                width: 35%;
            }
            &:nth-child(3) {
                width: 30%;
            }
        }
        .social.sone {
            width: 60%;
        }
        .social.stwo {
            width: 40%;
        }
        .social.sthree {
            width: 75%;
        }
    }
}

.topo .dropdown-menu,
.menu-influencer2 .dropdown-menu {
    left: auto;
    right: 0;
    cursor: pointer;
    min-width: auto;
    li {
        a,
        span {
            padding: 3px 8px;
        }
        a {
            background: $cse-blue;
            color: #fff;
            font-size: 12px;
            margin: 5px 5px 0;
            padding: 1px 2px;
            border-radius: 2px;
        }
        span {
            display: block;
            width: 100%;
        }
        i {
            opacity: 0.3;
            font-size: 12px;
            margin-right: 5px;
            ;
        }
        &.titulo {
            border-bottom: 1px solid #ccc;
            span {
                padding: 3px 8px 5px;
                color: #fff;
            }
        }
    }
    span.mini-span {
        font-size: 12px;
    }
}

.card-alert {
    float: left;
    color: $cse-green;
    position: absolute;
    display: block;
}

span.status-atual {
    font-size: 12px;
    color: #fff;
    padding: 2px 5px;
    border-radius: 2px;
    background: #ccc;
}

.sentenca {
    color: #999;
    font-size: 16px;
}

.media-icons-float .social-media-icon i.social-icon {
    background: transparent;
    padding: 0;
    height: auto;
    width: auto;
    margin-right: 2px; 
    &.facebook {
        color: lighten($facebook, 30%);
    }
    &.youtube {
        color: lighten($youtube, 30%);
    }
    &.instagram {
        color: lighten($instagram, 30%);
    }
    &.linkedin {
        color: lighten($linkedin, 30%);
    }
    &.googleplus {
        color: lighten($googleplus, 30%);
    }
    &.medium {
        color: lighten($medium, 30%);
    }
}

// FILTRO
.clear-search {
    @include box-shadow-none;
    display: inline;
    color: $cse-blue;
    font-size: 12px;
    background: transparent;
    &:hover {
        background: $cse-blue;
        color: #fff;
    }
}

.tag.js-tag {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: $cse-orange;
    color: #fff;
    border-radius: 4px;
    margin: 2px 10px 2px 0;
    padding: 3px;
    &::after{
        content: "";
        position: relative;
        right: -13px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 10px solid $cse-orange;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }
    &:before {
        content: "";
        position: relative;
        left: -3px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 10px solid $cse-bg;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
      }

      &:last-child:before,
      &:first-child:before{
          display: none;
      }
    &:nth-last-child(-n+2){
        &::after{display: none;}
        .js-tag-close {
            padding: 0 10px 0 0;
        }
    }
    .fa-user,
    .fa-users,
    .fa-thumb-tack {
        padding: 0 10px;
    }
    .tag-divisor {
        border-right: rgba(0, 0, 0, .1) solid 1px;
        height: 100%;
    }
    .tag-description {
        font-size: 12px;
        padding: 0 10px;
    }
    .js-tag-close {
        padding: 0;
        cursor: pointer;
    }
    i {
        color: white;
    }
    &.tags-clean {
        background-color: $basic-grey;
        cursor: pointer;
    }
}

.btn.buscar-filtro {
    display: inline-block;
    height: 40px;
    text-align: center;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    vertical-align: top;
    margin-left: 10px;
    width: 125px;
    padding-top: 8px;
    i {
        margin-right: 5px;
    }
}

a.add-button {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: $cse-blue;
    cursor: pointer;
    margin: 2px 2px 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    i {
        font-size: 13px;
        color: #fff;
    }
}

.loading-filter {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
}

.dropdown-menu.filter-scroll {
    max-height: 200px;
    overflow-y: scroll;
}

.filter-order {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
}

.filter-menu-block {
    display: inline-flex;
    align-items: center;
    color: #999;
    margin-left: 10px;
    &:first-child {
        margin-left: 0;
        margin-right: 1rem;
    }
    span {
        text-align: left;
        margin-right: 10px;
    }
    .page-view {
        button, .ui-button {
            font-family: Roboto, sans-serif !important;
            color: $basic-grey;
            background-color: white;
            border: $cse-grey solid 1px;
            -webkit-box-shadow: 1px 1px 1px $cse-grey;
            box-shadow: 1px 1px 1px $cse-grey;
            padding: 1px 10px 0;
            i{ font-size: 12px; }
            &:first-child {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
            }
            &:last-child {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }
            &.active, &.ui-state-active {
                background-color: transparent !important;
                -webkit-box-shadow: none;
                box-shadow: none !important;
                cursor: default !important;
                color: $basic-grey !important;
                &:hover{
                    background-color: transparent !important
                }
            }
            &:focus {
                outline: none;
                border: #d3d3d3 solid 1px !important;
            }
            &:hover {
                color: $basic-grey !important;
                background-color: white !important
            }
        }
    }
}

.coluna-lista .card.table-card {
    min-height: 10px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .table-card-item {
        margin-right: 20px;
    }
    .card-content {
        padding: 10px;
        width: 80%;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        justify-content: flex-start;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        .media-icons-float {
            display: initial;
        }
        .social-avatar,
        .name,
        .archetype,
        .social {
            margin: 0;
            text-align: left;
        }
        .social-avatar {
            max-width: 60px;
            max-height: 60px;
        }

    }
}


//===================================MOBILE
@media only screen and (max-width: 1360px) {
    .coluna-lista {
        &.exibe-lista {
            .card {
                .tags {
                    #dts-progress-bar {
                        .dts-card-subject {
                            display: block;
                            position: relative;
                            margin: 0 0 5px 0;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $screen-sm-max) {
    .coluna-filtro {
        padding: 0 15px;
        width: 100%;
        display: block;
        .col-md-6.pdr5,
        .col-md-6.pdl5 {
            padding: 0 15px 15px 15px;
        }
        .filter {
            display: none;
        }
    }
    .coluna-lista {
        width: 100%;
        padding: 15px 0;
        margin-right: -20px;
        display: block;
        &.exibe-lista {
            .card {
                .info-influencer-card {
                    & > div {
                        border-right: 0;
                    }
                }
                .info-influencer-card {
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                }
            }
        }
    }
    .text-r-mobile-l {
        text-align: left;
    }
    .sentenca {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: $screen-xs-max) {
    .top-bar .panel-heading {
        padding: 6px 10px;
        width: 100%;
    }
    .sentenca {
        margin-bottom: 0;
    }
}
