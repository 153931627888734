// Este é o arquivo para ESTILOS DO MODAL PADRÃO DO SOFTWARE
//
// Tips:
// - Para estilos basicos vá em: BASE.SCSS
// - Para palavras chaves, vá em VARIABLES.SCSS
// - Para animações, vá em ANIMATION.SCSS
// - Para componentes externos, vá em EXTERNAL-COMPONENTES.SCSS
.modal-dialog {
    margin-top: 100px;
}

.modal-cse {
    //GENERAL
    h1 {
        margin-top: 10px;
    }
    .modal-content {
        border-radius: 2px;
        border: 0;
    }
    .modal-header {
        padding: 15px 15px 0;
        border-bottom: none;
        display: flex;
        align-items: center;

        .close {
          background-color: transparent;
          border: none;
          color: $cse-grey;
          margin-left: auto;
          font-size: 21px;

          &:hover {
            color: $cse-darkgrey;
          }
        }

    }
    .modal-body {
        padding: 0 30px;
        text-align: center;
    }
    .ativity-ownership {
        text-align: left;
    }
    .alert.alert-warning {
        top: auto;
        margin: 0;
        text-align: center;
    }
    .modal-footer {
        padding: 30px;
    }
    span.modalinfo {
        background: $note-yellow;
        width: 100%;
        padding: 10px 15px;
        display: block;
        color: $basic-grey;
        font-size: 16px;
        font-weight: 500;
    }
    .row.explode {
        margin: 0 -45px;
    }
    .loading-modal img {
        margin: 30px 0 20px 0;
        width: 240px;
        height: 60px;
    } // INFLUENCERS DETAILS
    .new-influencer-description {
        @include truncate;
        width: 70%;
        display: block;
        float: left;
    }
    .influencer-line {
        padding: 0 0 15px;
        border-bottom: 1px dotted #ccc;
    }
    img.modal-list-img {
        display: block;
        width: 64px;
        height: 64px;
        border-radius: 50%;
    }
    .media-body {
        text-align: left;
        padding: 10px;
        display: block;
    }
    .total-fallowers {
        font-size: 40px;
        font-weight: 300;
        color: $cse-grey;
    }
    .tag-ladder-modal {
        display: inline-block;
        margin-left: 10px;
        background: #ccc;
        color: #fff;
        font-size: 12px;
        border-radius: 4px;
        padding: 0 6px;
        &:empty {
            display: none;
        }
    }
    .fixed-tip-new-influencer {
        padding: 5px 0;
        display: block;
        span {
            font-size: 13px;
        }
        span a {
            color: $cse-blue;
        }
        &.bigger span {
            font-size: 16px;
        }
    } // SEARCH DETAILS
    &.sugestion .modal-body {
        padding-bottom: 0;
    }
    .modal-content-height {
        position: relative;
        height: calc(100vh - 460px);
    }
    .sort a {
        margin-right: 10px;
    }
    .tiny.fright {
        margin-top: 2px;
    }
    .shorter-div a:hover {
        text-decoration: none;
        opacity: 0.4;
        cursor: pointer;
    }
    .sort span.active,
    .shorter-div a {
        color: $cse-blue;
    }
    .list-actions span {
        opacity: 0.7;
        cursor: pointer;
        -webkit-animation-name: arriving;
        animation-name: arriving;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        &:hover {
            opacity: 0.4;
        }
        &:active {
            opacity: 0;
            -webkit-animation-name: leaving;
            animation-name: leaving;
            -webkit-animation-duration: 3s;
            animation-duration: 3s;
        }
        &.already-on-the-list {
            cursor: default;
            -webkit-animation: none;
            animation: none;
        }
        &.already-on-the-list:hover {
            opacity: 0.7;
        }
        &.already-on-the-list:active {
            opacity: 0.7;
            -webkit-animation: none;
            animation: none;
        }
    }
    .modal-campaign {
        .modal-campaign-body {
            position: relative;
            -webkit-box-align: stretch;
            -ms-flex-align: stretch;
            align-items: stretch;
            margin-top: 35px;
            color: $basic-grey;
            .modal-campaign-body-list,
            .modal-campaign-body-no-list {
                -webkit-box-align: start;
                -ms-flex-align: start;
                align-items: flex-start;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                width: calc(100% - 200px);
            }
            .modal-campaign-body-no-list {
                -ms-flex-pack: distribute;
                justify-content: space-around;
                width: calc(100% - 220px);
                h3 {
                    margin: 0;
                }
            }
            .influencer-card-campaign {
                width: 180px;
            }
            .campaign-select-list {
                position: relative;
                width: 100%;
                height: 200px;
                background-color: $cse-bg;
                border-radius: 4px;
                .add-campaign-list {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                }
                .add-campaign-list-item {
                    position: relative;
                    -webkit-box-pack: start;
                    -ms-flex-pack: start;
                    justify-content: flex-start;
                    overflow: hidden;
                    text-align: left;
                    font-size: 16px;
                    padding: 10px 20px;
                    &:hover,
                    &:focus {
                        background-color: rgba(0, 0, 0, 0.05);
                    }
                    a {
                        color: $basic-grey;
                    }
                    i {
                        margin-right: 10px;
                    }
                    .list-campaign-name {
                        margin-right: 5px;
                    }
                    .list-actions {
                        margin-left: auto;
                    }
                    .list-actions,
                    .list-actions img {
                        display: block;
                        width: 30px;
                        height: 30px;
                    }
                    &.already {
                        img {
                            -webkit-filter: grayscale(100%);
                            filter: grayscale(100%);
                        }
                    }
                }
            }
            .list-alert {
                margin-top: auto;
                text-align: left;
                i {
                    color: $cse-orange;
                }
            }
        }
        .modal-footer {
            border-top: 0;
        }
        .btn-primary {
            width: 220px;
        }
    }
}

// ACTIVITIES
.activite,
.timeline-block .activite {
    i {
        font-size: 14px;
        margin: 0;
        color: inherit;
    }
    .input-group-addon {
        background: #fff;
        cursor: pointer;
        border-right: 0;
        a {
            color: $cse-blue;
        }
    }
    .input-group-addon:hover,
    .input-group-addon.active {
        background: $cse-blue;
        border: 1px solid $cse-blue;
        border-right: 0;
        a {
            color: #fff;
        }
    }
}

.activity-content {
    min-height: 400px;
    max-height: calc(100vh - 390px);
    /*overflow: hidden;*/
}

#activity-notes {
    height: 100px;
}

.modal-dialog.modal-lg.modal-cse.relationship .modal-footer {
    padding: 15px 30px 15px 30px;
}

.activity-maturity,
.activity-overcome {
    @include truncate;
}

.calendar .fc .fc-event.activity-complete {
    background: rgba(102, 102, 102, 0.5);
}

.calendar .fc .fc-event.activity-new {
    background: $cse-orange;
}

.calendar .fc .fc-event.activity-editing {
    background: "green";
}

//SPIN-MODAL
.influencer-line.spin {
    border-bottom: 1px solid #f6f6f6;
}

.spin-modal {
    -webkit-animation: opacity-loop 2s infinite;
    animation: opacity-loop 2s infinite;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 0.6;
    .media-body {
        padding-right: 30px;
    }
}

.fake-avatar {
    width: 64px;
    height: 64px;
    background-color: #ccc;
    border-radius: 50%;
    display: block;
    content: ' ';
    margin-bottom: 6px;
}

.data-fake {
    width: 100px;
    height: 16px;
    background-color: #ccc;
    border-radius: 4px;
    display: block;
    content: ' ';
}

.alert-modal-ativity {
    padding: 15px;
    border-radius: 4px;
    .texto {
        color: $basic-grey;
        opacity: 0.9;
    }
}

h1.ativity-title {
    margin-top: 0;
}

// CAMPANHAS ------------------------------------------------------------------
.campaign-modal-form {
    .modal-cse {
        .modal-header,
        .modal-body {
            padding: 30px;
        }
        .modal-header {
            text-align: center;
            border-bottom: 1px solid #e5e5e5;
            h2 {
                margin-top: 0;
            }
            span {
                color: $basic-grey;
            }
        }
        .modal-body {
            text-align: left;
        }
        .modal-footer {
            .btn {
                width: 180px;
            }
        }
    }
}

.modal-footer-question{
    display: block;
    margin-bottom: 5px;
    color: $basic-grey;
}

select.footer-question{
    height: 27px;
    padding: 2px 5px;
}
// MEDIA QUERIES ------------------------------------------------------------------------------------
@media only screen and (max-width: 1367px) {
    .modal-cse {
        .influencer-line {
            padding: 0 0 5px;
        }
        a.pull-left img.modal-list-img {
            width: 35px;
            height: 35px;
        }
        .media-body {
            padding: 0 0 5px 0;
        }
        .list-actions img {
            width: 35px;
            height: 35px;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .activity-row {
        .activity-content {
            .md-calendar {
                display: none !important;
            }
        }
    }
    .influencer-field {
        .img-circle {
            display: none;
        }
    }
}

@media only screen and (max-width: 991px) {
    .modal-dialog {
        width: 86%;
        margin: 60px auto 0;
        .orange-title {
            font-size: 22px;
            margin-top: 0;
        }
        .modal-body {
            padding-bottom: 0;
            .modal-content-height {
                padding-top: 30px;
                height: calc(100vh - 380px) !important;
                .cover-scroll {
                    opacity: 0;
                }
            }
        }
    }
    .ativity-ownership {
        span {
            strong {
                white-space: nowrap;
            }
        }
    }

}

@media only screen and (max-width: 480px) {
    .modal-cse {
        .modal-body {
            .shorter-div {
                width: 90%;
            }
            .ps-container {
                .ps-content {
                    padding: 0;
                }
            }
        }
        .modal-campaign{
            .modal-campaign-body.flex{
                display: block;
                .modal-campaign-body-list { width: 100%;}
            }
        }
    }
}

@media only screen and (max-width: $screen-xs-max) {
    //MODAL
    .xs-center {
        text-align: center !important;
    }
    .modal-dialog {
        width: 100%;
    }
    .modal-cse {
        margin-top: 80px;
        .modal-content {
            border-radius: 2px;
            border: 0;
        }
        .modal-body {
            text-align: center;
        }
        &.sugestion .modal-body {
            padding-bottom: 0;
        }
        .influencer-line {
            padding: 0 0 15px;
            border-bottom: 1px dotted #ccc;
        }
        img.modal-list-img {
            width: 40px;
            height: 40px;
        }
        .media-body {
            text-align: left;
            padding: 0 0 0 0;
        }
        .modal-footer {
            padding: 15px 30px 30px !important;
            text-align: center;
            .pull-right {
                float: none !important;
                margin: 0 auto;
            }
        }
        textarea.form-control {
            height: 80px;
            min-height: 80px !important;
        }
    }
    .modal-add-influencer {
        .modal-dialog {
            margin-top: 60px;
            h2 {
                font-size: 16px;
            }
            .sort.pull-right {
                display: none;
            }
            .shorter-div {
                padding-bottom: 0;
                margin-bottom: 0;
            }
            .modal-body {
                padding: 0 20px;
                .modal-scroll-box {
                    height: calc(100vh - 340px);
                    .influencer-line {
                        margin-bottom: 10px;
                        padding-bottom: 10px;
                    }
                }
            }
            .modal-footer {
                padding: 8px 20px 16px !important;
            }
        }
        &.campaign {
            .modal-body {
                .modal-scroll-box {
                    height: calc(100vh - 290px);
                }
            }
        }
    }
}
