// Este é o arquivo para os ESTILOS DE FUNIL
//
// Tips:
// - Para estilos basicos vá em: BASE.SCSS
// - Para palavras chaves, vá em VARIABLES.SCSS
// - Para animações, vá em ANIMATION.SCSS
// - Para componentes externos, vá em EXTERNAL-COMPONENTES.SCSS
// OBS: o dropdown e o ps-scroll são componentes externos
//BASIC STYLES FOR FUNNEL PAGES
h1.main {
    color: $cse-grey;
    text-transform: uppercase;
    font-size: 18px;
    text-align: center;
    margin-bottom: 40px;
    &.ladder {
        width: 100%;
        display: inline-block;
        &::before {
            margin-right: 5px;
        }
        &::after {
            margin-left: 5px;
        }
        &::before,
        &::after {
            content: " ";
            height: 0;
            border-bottom: 1px dotted $cse-grey;
            width: calc(50% - 107px);
            display: inline-block;
            vertical-align: middle;
            margin-top: -4px;
        }
    }
}

.menu-step {
    float: right;
    &:hover {
        background-color: transparent;
    }
    &.open .dropdown-toggle {
        box-shadow: none;
        -webkit-box-shadow: none;
    }
}

.main #ladder-influence {
    .funel-heading {
        padding: 0;
    }
    .panel-heading {
        padding-bottom: 0;
    }
    .funel-heading,
    .panel-heading {
        background: #fff;
        border: 0;
        label {
            font-size: 15px;
            font-style: italic;
            color: #ccc;
        }
    }
    .panel.panel-default.panel-bag {
        margin: 0px;
        padding: 5px 15px 15px 15px;
        border-radius: 4px;
        background-color: rgb(255, 255, 255);
        -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    }
    .panel-bag .panel-body {
        padding: 0;
        min-height: 100px;
    }
}

.col-md-3.col-influence {
    width: 20%;
}

// ESCADA DE INFLUENCIA  ===============================================================================================================//
.carousel-control.disabled > span {
    color: #ecf0f1 !important;
}

#ladder-influence {
    h3 {
        color: $cse-orange;
        margin-top: 0;
    }
    .dropdown-menu {
        left: auto;
        right: 0;
        cursor: pointer;
        a {
            padding: 3px 10px;
        }
        a,
        i {
            color: $cse-blue;
        }
        i {
            opacity: 0.3;
            font-size: 12px;
            margin-right: 5px;
        }
    }
}

.btn.material {
    display: none;
    -webkit-animation-name: arriving;
    animation-name: arriving;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
}

.ladder-panel {
    background-color: #fff;
    padding: 20px 15px 0;
    .alert-move {
        position: fixed;
        z-index: 99999;
        top: 65px;
        left: 0;
        right: 0;
        margin: 0 5px;
        width: auto;
    }
    .col-influence {
        padding: 0;
        .step-panel {
            position: relative;
            border-right: $cse-grey solid 1px;
            border-top: $cse-grey solid 1px;
            padding-bottom: 20px;
            .btn.material {
                width: 45px;
                height: 45px;
            }
        }
    }
}

#Promotor .step-panel {
    border-right: none;
}

//CAROUSEL
.carousel-control {
    top: -20px;
    bottom: auto;
}

.carousel.slide {
    padding: 0 0 20px;
    h4 {
        margin-top: 5px;
        color: #999;
    }
}

.carousel-indicators {
    li,
    li.active {
        margin: 0 5px;
        border: 0;
        background: #999;
        opacity: 0.5;
    }
    li.active {
        opacity: 1;
        width: 10px;
        height: 10px;
    }
}

.right.carousel-control,
.left.carousel-control {
    background-image: none;
    span.icon-next.carousel-control-next-icon,
    span.icon-prev.carousel-control-prev-icon {
        font-size: 70px;
        color: #999;
        text-shadow: none;
        opacity: 1;
        margin-top: 25px;
        line-height: 20px;
    }
}

.carousel-inner h4 {
    font-size: 32px;
}

.carousel-indicators {
    bottom: 0;
}

//TOPOS DA ESDADA DE INFLUENCIA
.col-influence {
    &:hover {
        .btn.material {
            display: block;
        }
    }
}

.steps {
    color: #999;
    font-weight: normal;
    span {
        background-color: #999;
        font-size: 10px;
        color: #fff;
        text-align: center;
        height: 16px;
        display: inline-block;
        border-radius: 4px;
        padding: 2px 4px;
        margin-top: 15px;
        position: absolute;
        opacity: 0.5;
    }
}

.title-description {
    display: none;
    background: $cse-green;
    color: #fff;
    position: absolute;
    padding: 15px 25px;
    font-size: 14px;
    font-weight: 400;
    margin: 40px 10px 0 10px;
    z-index: 99999;
    line-height: 20px;
    text-align: left; //box-shadow: -8px 16px 66px 0px #ccc, 0px 0px 0px 0px #fffdfd;
    -webkit-animation-name: appearing2;
    animation-name: appearing2;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
}

h4.steps {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 8px;
    cursor: help;
    &:hover .title-description {
        display: block;
    }
}

#Desconhecido {
    .line-arrow {
        display: none;
    }
}

.line-arrow {
    position: absolute;
    overflow: hidden;
    display: inline-block;
    font-size: 11.8px;
    /*set the size for arrow*/
    width: 4em;
    height: 4em;
    top: -5px;
    left: -24px;
    border-top: 1px solid #d3d3d3;
    border-right: 1px solid #d3d3d3;
    -webkit-transform: rotate(54deg) skew(20deg);
    -ms-transform: rotate(54deg) skew(20deg);
    transform: rotate(54deg) skew(20deg);
}

//======================================================= END
// CARD DO INFLUENCIADOR  ===================================//
.influencer-field {
    position: relative;
    border: #d2cece solid 1px;
    border-radius: 4px;
    background: #fff;
    margin: 0 auto 10px;
    padding: 10px;
    -webkit-box-shadow: 0 0 0 transparent;
    box-shadow: 0 0 0 transparent;
    -webkit-transition: -webkit-box-shadow 0.3s ease-in-out;
    transition: -webkit-box-shadow 0.3s ease-in-out;
    -webkit-transition: -webkit-box-shadow 0.3s ease-in-out;
    transition: -webkit-box-shadow 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
    &:hover {
        border: $cse-blue solid 1px;
        -webkit-box-shadow: $bs-level-1;
        box-shadow: $bs-level-1;
        .btn-remove {
            visibility: visible;
            opacity: 1;
        }
    }
    .btn-remove {
        visibility: hidden;
        opacity: 0;
        -webkit-transition: visibility 0s, opacity 0.3s linear;
        transition: visibility 0s, opacity 0.3s linear;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background: $cse-blue;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        font-size: 15px;
        position: absolute;
        top: -10px;
        right: -10px;
        cursor: pointer;
        i {
            color: white;
            margin-bottom: 2px;
        }
    }
    .inf-content {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        .name-social {
            line-height: 16px;
            margin-left: 10px;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            -ms-flex-negative: 5;
            flex-shrink: 5;
            overflow: hidden;
            .social-icon {
                background: transparent;
                padding: 0;
                height: auto;
                width: auto;
                margin-right: 5px;
                font-size: 12px; 
                &.facebook {
                    color: lighten($facebook, 30%);
                }
                &.youtube {
                    color: lighten($youtube, 30%);
                }
                &.instagram {
                    color: lighten($instagram, 30%);
                }
                &.linkedin {
                    color: lighten($linkedin, 30%);
                }
                &.googleplus {
                    color: lighten($googleplus, 30%);
                }
                &.medium {
                    color: lighten($medium, 30%);
                }
            }
        }
        .flags {
            margin-left: auto;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            .activity-flag {
                margin: 0;
            }
        }
    }
    .activity-list {
        margin: 15px -10px 0;
    }
}

.img-circle {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    overflow: hidden;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    img {
        max-height: 35px;
    }
    &.connect {
        height: 40px;
        width: 40px;
        background: url("/assets/img/user-default.png") no-repeat;
        background-size: 100% 100%;
    }
}

.influencer-name-link {
    display: block;
    label:hover {
        color: $cse-blue;
        cursor: pointer !important;
        font-weight: 600;
    }
}

.menu-influencer {
    a {
        cursor: pointer;
    }
    a.dropdown-toggle {
        color: $cse-grey;
    }
}

label.user-name {
    max-width: 100%;
    margin: 0;
    font-size: 14px;
    @include truncate;
}

.social-media-icon {
    font-size: 14px;
    img {
        width: 12px;
        height: auto;
        display: inline-block;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        opacity: 0.5;
    }
}


.media-icons-float {
    display: inline-block;
    margin-right: 0 5px;
}

.top-activity-search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    .list-view {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.ativity-counters {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    span {
        color: $basic-grey;
        font-size: 12px
    }
    i {
        margin-right: 2px;
    }
    a {
        padding: 2px 5px 4px 5px;
        border-radius: 8px;
    }
    a.active {
        background: #fff;
    }
    .btn-activity-status {
        background-color: #fff;
        border-radius: 5px;
        margin-right: 10px;
        border: $cse-grey solid 1px;
        padding: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-shadow: 1px 1px 1px $cse-grey;
        box-shadow: 1px 1px 1px $cse-grey;
        width: 155px;
        &.active {
            background: transparent;
            -webkit-box-shadow: none;
            box-shadow: none;
            .badge {
                background: transparent;
            }
        }
        .status {
            white-space: nowrap;
        }
        .badge {
            font-weight: normal;
            background: #fff;
            margin: 0 2px 0 auto;
            border-left: $cse-grey solid 1px;
            border-radius: 0;
        }
    }
}

.activity-flag {
    display: block;
    position: relative;
    cursor: pointer;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 0 8px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    .fa-chevron-up {
        display: none;
    }
    &.planned {
        background-color: $basic-grey;
    }
    &.today {
        background-color: $cse-green;
    }
    &.late {
        background-color: red;
    }
    &.no-activity {
        background-color: $cse-yellow;
    }
    &.close-list {
        background-color: $cse-grey;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .fa-chevron-up {
            display: block;
            color: white;
            margin-bottom: 2px;
        }
    }
    .flags & {
        width: 18px;
        height: 18px;
    }
}

ul.ativities {
    background: $cse-lightgrey;
    padding: 0;
    li {
        @include truncate;
        list-style: none;
        padding: 3px 20px;
        width: 170px;
        border-bottom: 1px solid #fff;
        &:hover {
            text-decoration: none;
            color: $cse-blue;
        }
    }
}

.link-activity {
    position: relative;
    width: 100%;
    padding: 6px 10px;
    border-bottom: 1px solid #fff;
    background: #f6f6f6;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    div {
        width: 100%;
    }
    span {
        font-size: 13px;
        color: $basic-grey;
    }
    a:hover {
        text-decoration: none;
    }
}

.add-activity {
    padding: 6px 10px 0;
    a:hover {
        text-decoration: none;
        opacity: 0.7;
    }
    span {
        color: $cse-blue;
    }
}

.ativity-category-title {
    border-top: 1px solid #f4f4f4;
    span {
        font-size: 12px;
        padding: 4px 0;
        display: block;
    }
    span.activity-late {
        color: #c0392b;
    }
}

a.name-ativity,
.icon-activity {
    font-size: 12px;
    color: $basic-grey;
}

a.activity-notes-ladder {
    @include truncate;
    margin-top: 5px;
    color: $basic-grey;
    font-size: 12px;
    font-style: italic;
    display: block;
    background: $note-yellow;
    padding: 2px 5px;
    border-radius: 2px;
}

.statusFlagDisabled {
    opacity: 0.3;
    cursor: default !important;
}

.line-direct {
    padding-top: 10px;
    margin: 10px -10px 0 -15px;
    border-top: $cse-grey solid 1px;
    font-size: 12px;
    a {
        color: #999;
    }
    .ld-up {
        text-align: right;
    }
}

//===================================MOBILE
@media only screen and (max-width: 1360px) {
    .img-circle {
        display: none;
        .gu-mirror & {
            display: none;
        }
    }
    .name-social {
        margin-left: 0 !important;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    body.main {
        .container {
            width: calc(100% - 20px);
        }
    }
    .tag-content {
        width: 100%;
    }
    #search.navbar-form.navbar-left.ng-untouched.ng-pristine.ng-valid {
        display: none;
    }
}

@media only screen and (max-device-width: 991px) {
    .filter-card-view {
        .ps-content {
            padding-bottom: 20px !important;
        }
    }
    .panel.panel-default.panel-bag {
        padding: 0 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
    .top-activity-search {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .line-direct {
        margin: 10px -10px 0;
        padding: 10px 10px 0;
    }
    .name-social {
        margin-left: 0 !important;
    }
}

@media only screen and (max-device-width: 768px) {
    .influencer-field .img-circle {
        display: none;
        .gu-mirror & {
            display: none;
        }
    }
    .tag-content {
        width: 100%;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
    .main {
        .ps-content {
            padding: 0 10px 10px;
        }
        .ps-container .ps-content {
            padding-bottom: 80px;
        }
    }
    .col-md-3.col-influence {
        width: 100%;
    }
    #search.navbar-form.navbar-left.ng-untouched.ng-pristine.ng-valid {
        display: none;
    }
    .menu-header {
        display: none;
    }
    .tag-content {
        padding-right: 5px;
    }
    .ladder-panel {
        padding: 20px 0 0;
        .panel-heading {
            background-color: white;
        }
    }
    .main #ladder-influence .panel.panel-default.panel-bag {
        padding: 5px 0 15px;
    }
}

@media (hover:none),
(hover:on-demand) {
    .btn-remove {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        visibility: visible !important;
        opacity: 1 !important;
    }
    .btn.material {
        display: block !important;
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 50px;
        height: 50px;
        font-size: 20px;
        margin-right: 0;
        @media only screen and (max-device-width: 991px) {
            position: relative;
            width: 50px;
            height: 50px;
            float: right;
            margin-top: 30px;
            margin-bottom: 20px;
        }
    }
    .influencer-field {
        margin-bottom: 20px;
    }
}
