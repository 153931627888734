// Este é o arquivo para os ESTILOS DE CAMPANHA
//
// Tips:
// - Para estilos basicos vá em: BASE.SCSS
// - Para palavras chaves, vá em VARIABLES.SCSS
// - Para animações, vá em ANIMATION.SCSS
// - Para componentes externos, vá em EXTERNAL-COMPONENTES.SCSS
//
//
//
/***** LISTA DE CAMPANHAS *****/

.filter-campaign {
    position: relative;
    width: 350px;
    float: right;
}

.campaign-list {
    -ms-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    margin: 0 -20px 0 0;
    .card {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: calc(25% - 20px);
        min-height: 220px;
        background-color: #fff;
        padding: 20px;
        margin: 0 20px 20px 0;
        -webkit-box-shadow: $bs-level-1-b;
        box-shadow: $bs-level-1-b;
        color: $basic-grey;
        &.card-new-camp {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            span {
                width: 120px;
                height: 120px;
                background-color: $cse-bg;
                border-radius: 50%;
                margin-bottom: 20px;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
            }
            i {
                font-size: 70px;
                color: white;
            }
            a {
                text-transform: uppercase;
            }
        }
        &:last-child {
            margin-right: auto;
        }
        .card-menu {
            position: absolute;
            display: block;
            top: 16px;
            right: 20px;
            font-size: 20px;
            .card-menu-trigger {
                display: inline-block;
                text-align: right;
                width: 20px;
                color: $cse-grey;
                cursor: pointer;
                font-size: 18px;
                font-weight: 400;
            }
            .dropdown-menu {
                min-width: unset;
                position: relative;
                margin-top: 25px;
                margin-right: -22px;
                li a {
                    padding: 0 10px;
                    color: $basic-grey;
                    &:hover {
                        color: $cse-blue;
                    }
                }
            }
        }
        .card-title {
            text-align: left;
            cursor: pointer;
            .card-name {
                font-size: 16px;
                text-transform: uppercase;
                font-weight: bold;
            }
            .card-details {
                font-size: 13px;
                font-style: italic;
                .not-started,
                .in-progress,
                .finished {
                    font-style: normal;
                    font-weight: bold;
                    margin-right: 5px;
                }
                .not-started {
                    color: $cse-orange;
                }
                .in-progress {
                    color: $cse-green;
                }
                .finished {
                    color: $cse-purple;
                }
            }
        }
        .card-body {
            cursor: pointer;
            .card-steps-list {
                list-style: none;
                margin-top: 10px;
                margin-bottom: 0;
                padding-left: 0;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                li {
                    width: 32%;
                    margin-top: 5px;
                    padding: 10px;
                    border: 1px solid;
                    border-color: $cse-grey;
                    border-radius: 4px;
                    text-align: center;
                }
                .step-full {
                    border-color: rgba(238, 117, 70, 0.4);
                }
                .step-total {
                    display: block;
                    font-weight: bolder;
                    font-size: 14px;
                }
                .step-name {
                    display: block;
                    text-transform: uppercase;
                    @include truncate;
                }
            }
        }
        .card-footer {
            margin-top: auto;
            .campaign-card-activities {
                margin: 20px 0 0 0;
                padding: 0;
                list-style: none;
                li {
                    display: flex;
                }
                .activity-flag {
                    display: inline-block;
                    margin: 0 10px 0 0;
                    cursor: default;
                }
            }
            .campaign-progress {
                display: block;
                position: relative;
                margin-top: 20px;
                &.progress {
                    margin-bottom: 0;
                    height: 10px;
                    background-color: $cse-bg;
                    border-radius: 4px;
                    -webkit-box-shadow: none;
                    box-shadow: none;
                    .progress-bar {
                        -webkit-box-shadow: none;
                        box-shadow: none;
                    }
                }
                &.not-started {
                    .progress-bar {
                        background-color: $cse-orange;
                    }
                }
                &.in-progress {
                    .progress-bar {
                        background-color: $cse-green;
                    }
                }
                &.finished {
                    .progress-bar {
                        background-color: $cse-purple;
                    }
                }
            }
        }
    }
    &.loading-01 {
        .card {
            .card-name,
            .card-details,
            .card-steps-list li,
            .campaign-progress {
                background-color: #ccc;
                border-radius: 4px;
                -webkit-animation-name: opacity-loop;
                animation-name: opacity-loop;
                -webkit-animation-duration: 2s;
                animation-duration: 2s;
                -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
            }
            .card-name {
                width: 60%;
                height: 20px;
            }
            .card-details {
                width: 90%;
                height: 14px;
                margin-top: 8px;
            }
            .card-steps-list {
                margin-top: 12px;
                li {
                    height: 55px;
                    border: none;
                }
            }
            .campaign-progress {
                margin-top: 20px;
                width: 100%;
                height: 10px;
            }
        }
    }
}

/***** DETALHES DA CAMPANHA *****/

.campaign-details {
    .page-title {
        .start-date,
        .end-date,
        .date {
            font-size: 14px;
        }
        .start-date {
            margin: 0;
        }
        .end-date {
            margin: 0 0 0 5px;
            &::before {
                content: "|";
                margin-right: 10px;
            }
        }
        .date {
            margin: 0;
            padding-left: 0;
            border-left: 0;
        }
    }
    h4.steps {
        cursor: default;
        position: relative;
        margin-top: 0;
        em {
            font-style: normal;
            max-width: 70%;
            display: inline-block;
            vertical-align: middle;
        }
        .campaign-edit {
            position: absolute;
            right: 10px;
            z-index: 1;
        }
    }
    .campaign-edit {
        font-size: 16px;
        color: $basic-grey;
        cursor: pointer;
        &:hover {
            font-size: 16px;
            color: $cse-blue;
        }
    }
    .campaign-step-edit {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        .form-control {
            display: inherit;
            width: 50%;
            height: 31px;
            margin-top: -5px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        a {
            margin-top: -5px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    .campaign-stage {
        .step-panel {
            position: relative;
        }
        &:hover {
            .btn-material {
                display: block;
            }
        }
        &:first-child {
            .line-arrow {
                display: none;
            }
        }
        &:last-child {
            .step-panel {
                border-right: none;
            }
        }
        .step-header {
            height: 57px;
            padding-top: 10px;
            em {
                cursor: text;
            }
        }
    }
    .funnel-mobile {
        .carousel-inner h4 {
            font-size: 22px;
            margin-top: 10px;
            .campaign-edit {
                position: relative;
                right: auto;
                display: inline-block;
                font-size: 20px;
                margin-left: 15px;
                vertical-align: middle;
            }
        }
        .campaign-step-edit {
            .form-control,
            a {
                margin-top: 6px;
                margin-bottom: 27px;
            }
        }
    }
    .influencer-field {
        .img-circle {
            display: none;
        }
        .name-social {
            margin-left: 0;
        }
    }
    .vhmobil {
        min-height: calc(100vh - 500px);
    }
}

.campaign-body .gu-mirror {
    .img-circle {
        display: none;
    }
    .name-social {
        margin-left: 0;
    }
}



/***** MOBILE *****/

@media only screen and (max-width: 1500px) {
    .campaign-list {
        .card {
            width: calc(33.33% - 20px);
        }
    }
}

@media only screen and (max-width: $screen-md-max) {
    .campaign-list {
        .card {
            width: calc(50% - 20px);
        }
    }
}

@media only screen and (max-width: $screen-sm-max) {
    .campaign-list {
        .card {
            .card-body {
                .card-steps-list li {
                    width: 48%;
                }
            }
        }
    }
}

@media only screen and (max-width: $screen-xs-max) {
    .filter-campaign {
        width: 100%;
        float: left;
    }
    .campaign-list {
        .card {
            -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
            order: 2;
            width: calc(100% - 20px);
            &.card-new-camp {
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-direction: row;
                flex-direction: row;
                -ms-flex-pack: distribute;
                justify-content: space-around;
                -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                order: 1;
                min-height: 0;
                span {
                    width: 50px;
                    height: 50px;
                    margin-bottom: 0;
                    i {
                        font-size: 22px;
                    }
                }
            }
            &:nth-last-child(2) {
                margin-bottom: 0;
            }
        }
    }
    .campaign-body {
        .campaign-details {
            .campaign-name {
                display: block;
                margin: 10px 0;
            }
            .page-title {
                .page-title-span {
                    margin-left: 0;
                    padding-left: 0;
                    border-left: 0;
                }
            }
        }
    }
}
