// Este é o arquivo para ESTILOS BASICOS DO SOFTWARE
//
// Tips:
// - Para palavras chaves, vá em VARIABLES.SCSS
// - Para animações, vá em ANIMATION.SCSS
// - Para componentes externos, vá em EXTERNAL-COMPONENTES.SCSS
html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  position: relative;
  min-height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: $cse-bg;
  font-size: 14px;
  line-height: 1.428571429;
  a {
    color: $cse-blue;
    &:hover {
      text-decoration: none;
    }
  }
}

a:hover,
a:focus {
  text-decoration: none;
}

h1 {
  font-size: 34px;
  color: $cse-orange;
  opacity: 0.8;
  font-weight: 400;
}

.logo-influencyme img {
  width: 250px;
  height: auto;
}

input {
  &::-webkit-input-placeholder {
    font-style: italic;
    color: $cse-grey;
  }
  &:-moz-placeholder {
    font-style: italic;
    color: $cse-grey;
  }
  &::-moz-placeholder {
    font-style: italic;
    color: $cse-grey;
  }
  &:-ms-input-placeholder {
    font-style: italic;
    color: $cse-grey;
  }
}

:focus {
  outline: none !important;
}

.btn-primary:focus {
  background-color: $cse-blue;
}

// UPDATE ANGULAR
body .ui-inputtext.ui-state-focus,
body .ui-inputtext:focus {
  outline: 0;
}
input:focus {
  &::-webkit-input-placeholder {
    color: transparent;
  }
  &:-moz-placeholder {
    color: transparent;
  }
  &::-moz-placeholder {
    color: transparent;
  }
  &:-ms-input-placeholder {
    color: transparent;
  }
}

.container.interna {
  padding-top: 80px;
  padding-bottom: 30px;
}

.search-div {
  padding: 5px 8px;
  border: $cse-grey solid 1px;
  border-radius: 100px;
  width: 100%;
  background: white;
  i {
    color: $cse-grey;
  }
  input {
    border: 0;
    background: transparent;
    &:focus {
      outline: none;
    }
  }
  &.search-customer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: normal;
    padding: 0;
    position: relative;
    input {
      flex-grow: 1;
    }
    input,
    a {
      padding: 5px 10px;
    }

    a {
      position: absolute;
      right: 0;
    }
  }
  &.escada {
    background-color: rgba(255, 255, 255, 0.7);
  }
  &.modal-add-new-influencer {
    margin-bottom: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    input {
      -webkit-box-flex: 10;
      -ms-flex-positive: 10;
      flex-grow: 10;
    }
    i {
      font-size: 20px;
      color: $cse-blue;
    }
  }
  &.search-dataset {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    .fa-search {
      font-size: 20px;
      margin-right: 5px;
    }
  }
}

.badge-info {
  background: #17a2b8;
  font-weight: 400;
}

.text-r-mobile-l {
  text-align: right;
}

.top-bar.minha-rede {
  display: block;
  margin-bottom: 20px;
}

.page-title {
  color: $cse-orange;
  margin-right: 15px;
  font-size: 18px;
  i {
    margin-right: 10px;
  }
  &-span {
    display: inline-block;
    background-color: transparent;
    font-size: 16px;
    color: $basic-grey;
    height: inherit;
    margin: 0 15px;
    padding-left: 16px;
    position: inherit;
    opacity: 0.8;
    border-radius: 0;
    border-left: $basic-grey solid 1px;
  }
}

.new-logo {
  width: 220px;
  height: 50px;
  margin: 30px auto 10px;
  img {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }
}

.panel.panel-default {
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  border-top: 0;
  color: $basic-grey;
  &.sign-in {
    padding: 60px;
    text-align: center;
  }
  &.error {
    h1 {
      color: $cse-error;
      font-size: 36px;
      opacity: 0.6;
    }
    .jumbotron {
      background: transparent;
    }
    .lead {
      font-weight: 500;
      font-size: 20px;
      color: $cse-grey;
    }
    .error-notes {
      padding: 30px;
      background: $note-yellow;
      border-radius: 8px;
      margin-bottom: 30px;
      word-wrap: break-word;
      strong {
        font-size: 18px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: $basic-grey;
      }
    }
  }
}

.shadow-divisor {
  margin: 0 -55px 25px -55px;
  height: 2px;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.tag-field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 2px;
  .tag.js-tag {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  #search {
    background-image: none;
    -ms-flex-negative: 10;
    flex-shrink: 10;
    -webkit-box-flex: 5;
    -ms-flex-positive: 5;
    flex-grow: 5;
  }
}

.orange-title {
  color: $cse-orange;
}

.lupa {
  margin: 3px 3px 0 5px;
  background: transparent;
  padding: 2px 5px;
  cursor: pointer;
  i {
    font-size: 25px;
    color: $cse-blue;
  }
  &:hover {
    i {
      color: darken($cse-blue, 10%);
    }
  }
}

span.inclusive {
  font-size: 16px;
  color: $basic-grey;
} // VALIDAÇÃO
.auth-panel {
  input.ng-invalid.ng-dirty {
    background-image: url("/assets/img/close.png");
    background-repeat: no-repeat;
    background-position: center right;
  }

  input.ng-valid.ng-dirty {
    background-image: url("/assets/img/check.png");
    background-repeat: no-repeat;
    background-position: center right;
  }

  .has-error .goals-block,
  textarea.ng-invalid.ng-dirty,
  select.ng-invalid.ng-dirty {
    border: $cse-error solid 1px;
  }
} // MENU MOBILE
#menu-mobile {
  background: $cse-bg;
  .container {
    height: 100vh;
    .row:nth-child(1) {
      background: $cse-mediungrey;
      color: white;
      padding: 15px 0;
      .col-xs-12:nth-child(1) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .user-info {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          .user-name {
            font-size: 16px;
          }
        }
        a {
          display: block;
          border-radius: 4px;
          width: 35px;
          height: 35px;
          text-align: center;
          background-color: $cse-blue;
          color: white;
          padding-top: 5px;
          i {
            font-size: 22px;
            vertical-align: middle;
          }
          &:nth-child(1) {
            margin-right: 15px;
          }
        }
        .badge {
          &.notifications {
            margin-left: -20px;
            background: $cse-red;
            margin-left: 20px;
            margin-top: -75px;
            font-size: 10px;
            border-radius: 4px;
          }
        }
      }
    }
    .row:nth-child(2) {
      .menu-title {
        display: block;
        text-transform: uppercase;
        color: $cse-mediungrey;
        font-size: 12px;
        margin: 20px 0 0;
      }
      .menu-divisor {
        display: block;
        border-bottom: $cse-mediungrey solid 1px;
        height: 4px;
        opacity: 0.6;
      }
      select {
        margin-left: -15px;
        margin-right: -15px;
        border-radius: 0;
        width: calc(100% + 30px);
        height: 44px;
        font-size: 16px;
        font-weight: bold;
        border-top: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: $cse-blue;
        text-indent: 0.01px;
        -o-text-overflow: "";
        text-overflow: "";
        background: white url("/assets/img/arrow-down-icon.png") no-repeat;
        background-size: 30px auto;
        background-position: center right;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        option {
          color: $cse-blue;
          font-weight: bold;
        }
        &::-ms-expand {
          display: none;
        }
      }
      .menu-mobile-ul {
        list-style: none;
        margin: 0 -15px;
        padding: 0;
        .link-header {
          background: white;
          .link-header-a {
            display: block;
            padding: 12px 0 10px 15px;
            font-size: 16px;
            font-weight: bold;
          }
        }
        .menu-divisor {
          height: 0;
        }
      }
    }
    .row:nth-child(3) {
      .col-xs-12 {
        text-align: center;
      }
    }
  }
}

// BADGES
.badge-success {
  background: $cse-green;
}

// FORMs
.form-control {
  font-size: 14px;
}

.badge-light {
  background: #eaf2f4;
}

.dropdown-menu > li > a {
  padding: 0;
}

label {
  font-size: 16px;
  color: $basic-grey;
  font-weight: normal;
  margin-bottom: 10px;
}

.form-group.checkbox-cse {
  margin-top: 30px;
  border-radius: 4px;
  label {
    margin: 0;
  }
}

.shorter-div {
  width: 70%;
  margin: 20px auto;
}

input.search-add {
  padding: 12px 40px 12px 12px;
  height: 44px;
  border: 1px solid $cse-blue;
  background: url("/assets/img/search.png") no-repeat#fff;
  background-size: 30px 34px;
  background-position: right center;
}

.form-danger {
  background-color: $cse-error;
  color: #fff;
  padding: 2px 6px;
  border-radius: 2px;
  position: absolute;
  font-size: 12px;
  margin: -5px 0 0 5px;
  z-index: 999999;
  &::before {
    content: "";
    position: absolute;
    border-style: solid;
    display: block;
    width: 0;
    top: -3px;
    left: 20px;
    bottom: auto;
    left: auto;
    border-width: 0 5px 5px;
    border-color: $cse-error transparent;
  }
  &.form-plan {
    position: relative;
  }
}

textarea.form-control:focus,
select.form-control:focus,
input.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

option:disabled {
  color: #ccc;
}

textarea {
  resize: none;
}

select.sort-drop-down {
  border: 1px solid #d6d6d6;
  color: $basic-grey;
  padding-top: 0;
  border-radius: 4px;
  &.active {
    border: 0;
  }
  option {
    border: 1px solid #fff;
  }
  option.disabled {
    opacity: 0.5;
  }
}

.ativite-list {
  display: inline-block;
  select.sort-drop-down {
    padding: 4px 12px;
    border-radius: 2px;
  }
}

ul.dropdown-menu li {
  cursor: pointer;
}

.divisor-pipe {
  color: #ccc;
}

//BUTTONS
.btn-primary {
  background-color: $cse-blue;
  border-color: $cse-blue;
}

.btn {
  border-radius: 2px;
  -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  font-size: 14px;

  &.material {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    font-size: 20px;
    bottom: 20px;
    right: 20px;
    z-index: 1;
  }
  &.search {
    width: 80px;
    height: 80px;
    font-size: 30px;
    bottom: 40px;
    margin-right: 40px;
    position: fixed;
  }
  &.android {
    color: $cse-blue;
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    &.btn-danger {
      color: $cse-red;
    }
    &.inverted {
      color: $cse-grey;
    }
    &.inverted:hover {
      color: $cse-blue;
    }
  }
  &.btn-default {
    border: 1px solid #ccc;
    color: $basic-grey;
    &.active {
      border: 0;
      background: $cse-blue;
      span,
      i {
        color: #fff;
      }
    }
  }

  &:disabled {
    pointer-events: all;
    cursor: not-allowed;
    opacity: .35;
    box-shadow: none;
  }
}

.ime-btn-orange {
  background-color: $cse-orange;
  color: #fff;
  border-color: $cse-orange;

  &:hover {
    background-color: lighten($cse-orange, 5%);
    color: #fff;
  }
}

.ime-btn-orange-outline {
  background-color: transparent;
  border-color: $cse-orange;
  color: $cse-orange;

  &:hover {
    background-color: $cse-orange;
    color: #fff;
    border-color: $cse-orange;
  }
}

.btn-block {
  font-size: 16px;
  padding: 20px;
  text-transform: uppercase;
  &.smaller {
    padding: 15px;
    font-size: 14px;
  }
}

.btn-outline {
  background-color: transparent;
  color: inherit;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-box-shadow: none;
  box-shadow: none;
  &.btn-primary {
    color: $cse-blue;
    border: 1px solid $cse-blue;
    &:hover {
      background-color: $cse-blue;
    }
  }
  &.btn-secondary {
    color: #666;
    border: 1px solid #666;
    &:hover {
      color: #666;
    }
  }
  &.btn-success {
    color: #5cb85c;
    border: 1px solid #5cb85c;
  }
  &.btn-info {
    color: #5bc0de;
    border: 1px solid #5bc0de;
  }
  &.btn-warning {
    color: #f0ad4e;
    border: 1px solid #f0ad4e;
  }
  &.btn-danger {
    color: #d9534f;
    border: 1px solid #d9534f;
  }
  &:hover {
    color: #fff;
  }
}

a.loginBtn {
  //SOCIAL
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 12px 0 0;
  padding: 8px 10px 8px 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
  text-transform: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  &::before {
    content: "";
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 100%;
  }
  &:focus {
    outline: none;
  }
  &:active {
    -webkit-box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
  }
}

/* Facebook */

a.loginBtn--facebook {
  background-color: #4c69ba;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#4c69ba),
    to(#3b55a0)
  );
  background-image: -o-linear-gradient(#4c69ba, #3b55a0);
  background-image: linear-gradient(#4c69ba, #3b55a0);
  &::before {
    border-right: #364e92 1px solid;
    background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png")
      6px 14px no-repeat;
  }
  &:hover,
  &:focus {
    background-color: #5b7bd5;
    color: #fff;
  }
}

/* Google */

a.loginBtn--google {
  background: #dd4b39;
  &::before {
    border-right: #bb3f30 1px solid;
    background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png")
      6px 14px no-repeat;
  }
  &:hover,
  &:focus {
    background: #e74b37;
    color: #fff;
  }
}

.this-is-a-link {
  a,
  span {
    color: $cse-blue;
    font-weight: 500;
  }
}

.search-results {
  height: 100%;
  overflow: scroll;
}

.glyphicon-refresh-animate {
  animation: spin 0.7s infinite linear;
  -webkit-animation: spin2 0.7s infinite linear;
}

.social-loader-icon {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.social-loader-screen {
  //   display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
} // page exibition styles
.list-view {
  padding-top: 5px;
  a {
    color: $cse-orange;
  }
}

.top-bar.minha-rede {
  /*    border-bottom: 1px solid #fff;
padding-bottom: 10px;*/
  .list-view {
    font-size: 18px;
    &.default {
      color: $basic-grey;
    }
  }
  a:hover {
    color: $cse-blue;
    cursor: pointer;
  }
}

span.inclusive {
  font-size: 16px;
  color: $basic-grey;
}

// OVERLAY configurações de conta
.account-manager {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 120px 0 30px;
  -webkit-animation-name: appearing;
  animation-name: appearing;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  .title {
    color: $basic-grey;
    font-size: 50px;
    font-weight: 400;
  }
  h5,
  h4 {
    color: $basic-grey;
  }
  a.btn-close {
    font-size: 24px;
    color: $cse-grey;
    &:hover {
      color: $cse-blue;
    }
  }
  .user-data {
    background: $cse-lightgrey;
    padding: 20px;
  }
}

.account-account-manager {
  .user-data {
    .change-password {
      padding: 0;
      margin-bottom: 30px;
      .form-control {
        height: 32px;
      }
    }
  }
}
// update angular
.time-counting {
  font-size: 18px;
}
// SPIN DEFAULT

.spin-default {
  width: 100%;
  height: 100%;
  background-color: $cse-bg;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  span {
    color: #989898;
    font-weight: bold;
    font-size: 28px;
  }
}

.invite-user {
  .form-danger {
    margin: 30px 0 0 5px;
  }
}

.user-infos-list span {
  display: inline-block;
  vertical-align: top;
}

.user-line {
  margin: 0;
  padding: 15px 0;
  border-bottom: 1px solid #f4f4f4;
  &:last-child {
    border-bottom: 0;
  }
}

.ui-accordion-content.ui-widget-content {
  padding: 0 !important;
}

.ui-accordion-header {
  &.ui-state-active {
    background: $cse-blue !important;
  }
  a {
    &:focus {
      text-decoration: none !important;
    }
  }
}

.danger .modal-dialog .modal-header,
.default .modal-dialog .modal-header,
.sucess .modal-dialog .modal-header {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  p,
  span {
    color: #ccc;
  }
  i {
    margin-right: 10px;
    font-size: 24px;
  }
}

.sucess .modal-dialog .modal-header {
  background: $cse-green;
  color: #fff;
}

.default .modal-dialog .modal-header {
  background: #fff;
  color: $basic-grey;
}

.breve {
  animation-name: breve;
  animation-duration: 2s;
  opacity: 0;
}

.breve2 {
  animation-name: breve2;
  animation-duration: 2s;
  opacity: 0;
}

// UPDATE ANGULAR
.updating-field-width span {
  width: 100%;
}

// LOADER DEFAULT
.loader-pattern {
  -webkit-animation-name: opacity-loop;
  animation-name: opacity-loop;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background: #ccc;
  color: rgba(0, 0, 0, 0);
  &.wrap,
  &.name-profile {
    margin-bottom: 10px;
    border-radius: 4px;
  }
  &.avatar-profile {
    border: 0;
  }
}

// LOADER HELPER
.make-it-a-loader-block {
  display: block;
  background: $cse-grey;
  width: 100%; //color: transparent !important;
  border-radius: 4px;
  -webkit-animation: opacity-loop;
  animation: opacity-loop;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  margin-bottom: 20px;
  content: "";
}

.danger .modal-dialog .modal-header {
  background: $cse-orange;
  color: #fff;
}

.account-confirmation-alert .alert {
  z-index: 999;
  top: auto;
  bottom: 5px;
  padding: 20px;
  text-align: center;
  span {
    margin-right: 10px;
  }
  h2 {
    color: $cse-orange;
    margin: 0 0 15px 0;
  }
  a {
    font-size: 24px;
  }
}

body #menu-mobile {
  display: none;
}

body.menu-open #menu-mobile {
  display: block;
  width: 100%;
  z-index: 903;
  left: 0;
  position: fixed;
  top: 60px;
  overflow: auto;
  padding-bottom: 20px;
}

.ativity-container-loader {
  position: absolute;
}

.quick-loading {
  font-size: 100%;
  color: $cse-grey;
  margin-bottom: 10px;
  i {
    margin-right: 5px;
    .adv-filters .adv-filter-input:hover & {
      color: $cse-grey;
    }
  }
}

.ui-helper-clear-fix {
  border-bottom: 1px solid #d5d5d5;
  img {
    width: 32px;
    display: inline-block;
    margin: 5px 0 2px 5px;
  }
  .branc-uhcf {
    width: 32px;
    display: inline-block;
    margin: 5px 0 2px 5px;
  }
}

//NOTIFICATIONS
.notification-line {
  border-bottom: 1px solid #ccc;
  padding: 15px;
  a {
    color: $basic-grey;
  }
  span {
    margin-right: 3px;
  }
  span.name {
    font-weight: bold;
  }
  &:hover {
    background: $cse-lightblue;
    cursor: pointer;
  }
}

i.atrasada {
  color: $cse-error !important;
}

i.executada {
  color: $cse-green !important;
}

.notification-description,
.icon-bowl {
  display: inline-block;
  vertical-align: middle;
}

.icon-bowl {
  margin-right: 10px;
  width: 30px;
  height: 30px;
  content: " ";
  border-radius: 50%;
  background: #fff;
  padding: 5px;
  text-align: center;
}

.notification-description {
  width: calc(100% - 50px);
} //SOCIAL ICON
.social-icon {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  color: #fff;
  padding-top: 4px; 
  &.facebook {
    background: $facebook;
  }
  &.youtube {
    background: $youtube;
  }
  &.instagram {
    background: $instagram;
  }
  &.tiktok {
    background: $tiktok;
  }
  &.linkedin {
    background: $linkedin;
  }
  &.googleplus {
    background: $googleplus;
  }
  &.medium {
    background: $medium;
  }
  &.blog {
    background: $cse-orange;
  }
  &.wiki {
    background: $cse-grey;
  }
} // my accounts
ul#minha-conta li {
  a {
    background: #fff;
    font-weight: bold;
  }
  &:hover a {
    background: $cse-blue;
    color: #fff;
  }
  &.active a {
    background: $cse-blue;
  }
}

.panel-tip {
  border-radius: 4px;
  background: $note-yellow;
  width: 100%;
  padding: 15px;
  color: $basic-grey;
}

#conectar {
  a.loginBtn--facebook,
  a.loginBtn--google  {
    margin: 10px 0 0;
    line-height: 45px;
    &::before {
      background-position-y: 17px;
    }
  }
  .influencer-field {
    &:hover {
      border: #d2cece solid 1px;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    .influencer-name-link label:hover {
      color: #999;
      font-weight: normal;
    }
  }
} // PÁGINA ATIVIDADES--------------------------------------------------------------
.pick-date {
  width: 400px;
  i {
    display: inline-block;
    color: $cse-grey;
  }
  h5 {
    color: $basic-grey;
  }
  .from-to i {
    font-size: 20px;
    color: $basic-grey;
    padding-top: 8px;
  }
}

.ui-select-choices-row,
.ui-select-match-text {
  &.active {
    a {
      background-color: transparent !important;
      label {
        color: $cse-blue;
        cursor: pointer;
      }
    }
  }
  .modal-list-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
  }
  .user-name {
    display: inline-block;
    vertical-align: middle;
  }
}

.input-group .ui-select-choices[_ngcontent-c1] {
  margin-top: 32px;
}

.ui-select-container.dropdown.open.show {
  .ui-select-toggle {
    .ui-select-match-text {
      margin-top: -4px;
    }
  }
}

#drop-atividades {
  .select-de-atividades {
    width: 220px;
    min-width: 200px;
    border: none;
    -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);
    .ui-dropdown-label,
    .ui-dropdown-trigger {
      background-color: $cse-green;
      color: white;
      text-align: center;
    }
    .ui-dropdown-panel {
      .ui-dropdown-filter-container {
        width: 100%;
      }
      .ui-dropdown-item {
        .ui-helper-clearfix {
          div {
            float: left !important;
            margin-left: 40px;
          }
          img {
            border-radius: 50%;
          }
        }
      }
    }
    .ui-dropdown-trigger {
      span {
        color: white;
      }
    }
  }
  &:hover {
    .select-de-atividades {
      .ui-dropdown-label,
      .ui-dropdown-trigger {
        background-color: darken($cse-green, 5%);
        border-radius: inherit;
      }
    }
  }
}

#loading-select-atividades {
  &.select-de-atividades {
    width: 220px;
    min-width: 200px;
    -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    .ui-dropdown-label {
      background-color: lighten($cse-green, 5%);
      color: white;
      text-align: center;
      width: 100%;
      height: 100%;
      font-size: 14px;
      padding: 7px 10px;
      margin-bottom: 0;
      border-radius: 4px;
    }
  }
}

.filters-on-left {
  .filter {
    display: inline-block;
    margin: 0 10px 20px 0;
  }
} // ADICIONAR ATIVIDADE-----------------------------------------------------------------------------------------------
.icon-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .input-group-addon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 40px;
    height: 34px;
  }
  .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

/*** ANIMAÇÃO DO ÍCONE DE ESCADA DO CARD ***/

.anim-ladder {
  animation-name: appearing2;
  animation-duration: 1s;
}

// ANIMACAO LOADER---------------------------------------------------------------------------------------------------
.sk-four-bounce {
  .sk-child {
    display: inline-block;
    -webkit-animation: sk-four-bounce 1.4s ease-in-out 0s infinite both;
    animation: sk-four-bounce 1.4s ease-in-out 0s infinite both;
    &.sk-bounce1 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
    &.sk-bounce2 {
      -webkit-animation-delay: -0.24s;
      animation-delay: -0.24s;
    }
    &.sk-bounce3 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }
  }
}

th.short {
  width: 45px;
}

th.medium {
  width: 100px;
}

th.big {
  width: 150px;
}

td span.ui-cell-data span {
  @include truncate;
  width: 100%;
  display: block;
}

.ui-datatable td {
  color: #666;
}

th.short .ui-column-title {
  display: none;
}

.spinner-card {
  margin: 0 auto;
  width: 70px;
  text-align: center;
}

.spinner-card > div {
  width: 18px;
  height: 18px;
  background-color: #ccc;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner-card .bounce1x {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner-card .bounce2x {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.pagination {
  li {
    cursor: pointer;
    a,
    a:hover,
    a:focus,
    span:hover,
    span:focus {
      color: $cse-blue;
    }
  }
  .active > a,
  .active > a:hover,
  .active > a:focus,
  .active > span,
  .active > span:hover,
  .active > span:focus {
    background-color: $cse-blue;
    border-color: $cse-blue;
  }
}

.spinner-card .bounce1x {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner-card .bounce2x {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
} // Margem inferior da paginação
body .ui-datatable .ui-paginator {
  margin-bottom: 20px;
}

#ladder-signal {
  color: $cse-green;
  .Unaware & {
    .st00,
    .st01,
    .st02,
    .st03 {
      opacity: 0.3;
    }
  }
  .Aware & {
    .st00,
    .st01,
    .st02 {
      opacity: 0.3;
    }
  }
  .Interested & {
    .st00,
    .st01 {
      opacity: 0.3;
    }
  }
  .Engaged & {
    .st00 {
      opacity: 0.3;
    }
  }
}

.sucess-green {
  color: $cse-green;
}

/*** TAG BETA ***/

.beta {
  &::after {
    content: "BETA";
    font-size: 10px;
    display: block;
    padding: 0 5px;
    color: white;
    font-weight: bold;
    background: $cse-grey;
    position: absolute;
    // border-radius: 10px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    right: 8px;
    top: -14px;
    transition: 0.3s;
  }
  &:hover::after,
  &:focus::after {
    background: $cse-blue;
  }
}

/***** BARRAS DE PROGRESSSO COM TEXTO NEGATIVO *****/

#dts-progress-bar {
  position: relative;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  .dts-progress-container {
    position: relative;
    width: 100%;
    border-radius: 15px;
    background-color: $cse-bg;
    overflow: hidden;
    z-index: 2;
    .dts-progress-content {
      position: relative;
      padding: 0 8px;
      white-space: nowrap;
    }
    .dts-positive {
      position: relative;
      color: white;
      background-color: $basic-grey;
      overflow: hidden;
      z-index: 1;
    }
    .dts-negative {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      color: $basic-grey;
      z-index: 0;
    }
  }
  .dts-card-subject {
    display: block;
    position: relative;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
    &.dts-highlighted {
      .dts-positive {
        background-color: $cse-orange;
      }
      .dts-negative {
        color: $cse-orange;
      }
    }
  }
  .dts-profile-subject {
    position: relative;
    width: 33.33%;
    padding: 0 5px 5px;
    &:last-child {
      margin-right: auto;
    }
    .dts-progress-container {
      display: block;
      position: relative;
    }
    &.dts-interests {
      .dts-positive {
        background-color: $cse-orange;
      }
      .dts-negative {
        color: $cse-orange;
      }
    }
    &.dts-brands {
      .dts-positive {
        background-color: #ff6384;
      }
      .dts-negative {
        color: #ff6384;
      }
    }
    &.dts-places {
      .dts-positive {
        background-color: $cse-purple;
      }
      .dts-negative {
        color: $cse-purple;
      }
    }
  }
  &.dts-columns {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

// TRIAL END

.argument {
  display: block;
  width: calc(100% - 70px);
}

.argument-line {
  padding: 20px 0 10px;
  border-bottom: 1px dotted #ccc;
  img {
    width: 50px;
    height: auto;
  }
}

.trial-stats-block {
  display: block;
  margin-bottom: 30px;
  padding: 6px 8px;
  border-radius: 10px;
  border: 1px solid #cfecff;
  background: #fff;
  .icon {
    border-radius: 6px;
    background: #ffc107;
    color: #fff;
    width: 60px;
    height: 60px;
    font-size: 30px;
    padding-top: 8px;
    text-align: center;
  }
  p {
    width: calc(100% - 75px);
    margin-left: 10px;
    margin-bottom: 0;
  }
  .icon,
  p {
    display: inline-block;
    vertical-align: middle;
  }
}

/***** SUBSTITUIÇÃO HTML + CSS PARA O PERFECT SCROLLBAR *****/

::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: $cse-grey;
}

body {
  &::-webkit-scrollbar-thumb {
    background-color: $basic-grey;
  }
}

.is-scroll {
  overflow-x: hidden;
  overflow-y: scroll;
}

.inner-funnel {
  position: relative;
  height: calc(100vh - 330px);
  padding-right: 10px;
  .panel-drag {
    height: calc(100% - 15px);
    .influencer-tag {
      display: block;
      position: relative;
      &:last-child {
        margin-bottom: 50px;
      }
    }
  }
  .influencer-tag:last-child {
    margin-bottom: 30px;
  }
  &::-webkit-scrollbar {
    width: 6px;
    z-index: 0;
  }
}

.scroll-cover {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 11px;
  background-color: white;
  transition: all 0.3s;
  opacity: 1;
}

.campaign-stage,
.col-influence {
  &:hover {
    .scroll-cover {
      transition: all 0.3s;
      opacity: 0;
    }
  }
}

.modal-scroll-box {
  position: relative;
  width: 100%;
  height: calc(100vh - 460px);
  margin-top: 20px;
  .card-influencer {
    .influencer-line {
      margin-bottom: 15px;
    }
    &:last-child {
      .influencer-line {
        margin-bottom: 0;
        border-bottom: 0;
      }
    }
  }
}

.trial-tag-container {
  position: fixed;
  margin: -20px auto 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 999;
}
.trial-tag {
  width: 160px;
  border-radius: 2px;
  background-color: $note-yellow;
  font-size: 12px;
  padding: 4px 10px;
  text-align: center;
  color: $cse-orange;
  font-weight: 600;
  &.trial-period {
    background-color: $cse-orange;
    color: #fff;
  }
}

.customer-edit-date {
  width: 100%;
  .ui-inputtext {
    width: 100%;
  }
}

.daily-consumption-chart {
  .daily-consumption-chart-container {
    display: flex;
    height: 190px;
    div {
      width: 100%;
      height: auto;
    }
  }
}

.text-cse-blue {
  color: $cse-blue;
}

// MOBILE STYLES-----------------------------------------------------------------------------------------------------------------------------
// MENU MOBILE
.menu-mobile {
  display: none;
  margin: 10px 20px;
}

.mat-sidenav-container {
  min-height: calc(100vh - 60px);
  position: fixed !important;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
}

.mat-icon {
  height: 40px;
  width: 40px;
}

md-sidenav {
  width: 70%;
}

/*479px & lower*/
@media only screen and (max-width: $screen-xs-max) {
  .panel.panel-default,
  .panel.panel-default.sign-in {
    padding: 10px 15px;
  }
  .menu-mobile {
    display: block;
    i.fa-bars {
      font-size: 30px;
      color: $cse-blue;
      margin-top: 4px;
    }
  }
  .h100 {
    height: 100vh;
  }
  .truncate-block {
    overflow: hidden;
    position: relative;
    line-height: 1.2em;
    max-height: 3.4em;
    text-align: justify;
    margin-right: -1em;
    padding-right: 1em;
    &::before {
      content: "...";
      position: absolute;
      right: 0;
      bottom: 0;
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      width: 1em;
      height: 1em;
      margin-top: 0.2em;
      background: white;
    }
  }
  .icon-group {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    .input-group-addon {
      margin-bottom: -1px;
      &:nth-child(1) {
        border-bottom-left-radius: 0;
      }
      &:nth-child(6) {
        border-right: 1px solid #ccc !important;
        border-top-right-radius: 4px;
      }
    }
    .form-control {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      border-bottom-left-radius: 4px;
    }
  }
  #minha-conta {
    text-align: center;
  }
  #conectar {
    margin-top: 30px;
  }
  .shadow-divisor {
    margin: 0 -15px 25px -15px !important;
  }
  #filter-tags {
    margin-top: 10px;
  }
  #dts-progress-bar .dts-profile-subject {
    width: 100% !important;
  }
  .modal-line-item {
    display: block;
    width: 100%;
    text-align: center;
  }
  .activity-details-top {
    padding-bottom: 20px;
    border-bottom: 1px solid $cse-lightgrey;
  }
  .modal-scroll-box {
    height: calc(100vh - 480px);
  }
  .trial-tag-container {
    position: fixed;
    top: 12px;
    margin: 0 auto 0 180px;
    display: block;
    z-index: 9999999;
    left: 180px;
    width: 70px;
  }
  .trial-tag {
    width: 70px;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1;
    span:first-child {
      display: block;
      font-size: 14px;
    }
    &.trial-last-day {
      padding: 4px 5px;
      width: 85px;
      text-transform: none;
    }
  }
  #btnActivityFilters .btn-default {
    padding: 6px 10px;
  }
  #intercom-container .intercom-launcher-discovery-frame,
  #intercom-container .intercom-launcher-frame {
    right: 5px !important;
    bottom: 5px !important;
  }
  // TABELA DE ATIVIDADES

  .action-activity-button {
    .btn {
      width: 100%;
      text-align: center;
    }
    .btn span,
    .btn i {
      display: inline-block;
      vertical-align: middle;
    }
    .btn span {
      text-align: left;
      width: auto;
    }
  }
}

@media only screen and (min-width: 1920px) {
  body.main {
    .container {
      max-width: 1920px;
    }
  }
}

@media only screen and (min-width: 768px) {
  body.main {
    .container {
      max-width: calc(100% - 80px);
    }
    .login-page.container {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 480px) {
  .panel-heading-search {
    position: relative !important;
  }
  .user-line {
    .col-md-3 {
      text-align: left !important;
    }
    .btn.android {
      padding-left: 0 !important;
    }
  }
}

@media only screen and (max-width: $screen-sm-max) {
  input,
  select,
  textarea {
    font-size: 16px !important;
  }
  .mob-mb {
    margin-bottom: 15px;
  }
  .page-title {
    margin-right: 5px;
    &-span {
      font-size: 14px;
      margin-left: 5px;
      padding-left: 6px;
      border-left: $basic-grey solid 1px;
    }
  }
  th.short,
  th.medium,
  th.big {
    width: auto;
  }
  tr.ui-widget-content.ui-datatable-odd,
  tr.ui-widget-content.ui-datatable-even {
    .ui-column-title {
      color: $basic-grey;
    }
    td:nth-child(even) {
      background: #fff;
    }
    th.short .ui-column-title {
      display: block;
    }
  }
  .new-logo {
    margin: 30px auto;
  }
  #minha-conta {
    padding-right: 0px;
  }
  .account-account-manager {
    .user-data {
      .change-password {
        margin-bottom: 0;
      }
    }
  }
  .shadow-divisor {
    margin: 0 -25px 25px -25px;
  }
}

@media only screen and (max-width: $screen-md-max) {
  .shadow-divisor {
    margin: 0 -25px 25px -25px;
  }
  #dts-progress-bar .dts-profile-subject {
    width: 50%;
  }
}

// ADD XL-COL SUPORT TO BOOTSTRAP

@media (min-width: 1200px) and (max-width: 1599px) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-xl-block,
.visible-xl-inline,
.visible-xl-inline-block,
.visible-xl {
  display: none !important;
}

@media (min-width: 1600px) {
  .container {
    width: 1570px;
  }

  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12 {
    float: left;
  }

  .col-xl-12 {
    width: 100%;
  }

  .col-xl-11 {
    width: 91.66666667%;
  }

  .col-xl-10 {
    width: 83.33333333%;
  }

  .col-xl-9 {
    width: 75%;
  }

  .col-xl-8 {
    width: 66.66666667%;
  }

  .col-xl-7 {
    width: 58.33333333%;
  }

  .col-xl-6 {
    width: 50%;
  }

  .col-xl-5 {
    width: 41.66666667%;
  }

  .col-xl-4 {
    width: 33.33333333%;
  }

  .col-xl-3 {
    width: 25%;
  }

  .col-xl-2 {
    width: 16.66666667%;
  }

  .col-xl-1 {
    width: 8.33333333%;
  }

  .col-xl-pull-12 {
    right: 100%;
  }

  .col-xl-pull-11 {
    right: 91.66666667%;
  }

  .col-xl-pull-10 {
    right: 83.33333333%;
  }

  .col-xl-pull-9 {
    right: 75%;
  }

  .col-xl-pull-8 {
    right: 66.66666667%;
  }

  .col-xl-pull-7 {
    right: 58.33333333%;
  }

  .col-xl-pull-6 {
    right: 50%;
  }

  .col-xl-pull-5 {
    right: 41.66666667%;
  }

  .col-xl-pull-4 {
    right: 33.33333333%;
  }

  .col-xl-pull-3 {
    right: 25%;
  }

  .col-xl-pull-2 {
    right: 16.66666667%;
  }

  .col-xl-pull-1 {
    right: 8.33333333%;
  }

  .col-xl-pull-0 {
    right: auto;
  }

  .col-xl-push-12 {
    left: 100%;
  }

  .col-xl-push-11 {
    left: 91.66666667%;
  }

  .col-xl-push-10 {
    left: 83.33333333%;
  }

  .col-xl-push-9 {
    left: 75%;
  }

  .col-xl-push-8 {
    left: 66.66666667%;
  }

  .col-xl-push-7 {
    left: 58.33333333%;
  }

  .col-xl-push-6 {
    left: 50%;
  }

  .col-xl-push-5 {
    left: 41.66666667%;
  }

  .col-xl-push-4 {
    left: 33.33333333%;
  }

  .col-xl-push-3 {
    left: 25%;
  }

  .col-xl-push-2 {
    left: 16.66666667%;
  }

  .col-xl-push-1 {
    left: 8.33333333%;
  }

  .col-xl-push-0 {
    left: auto;
  }

  .col-xl-offset-12 {
    margin-left: 100%;
  }

  .col-xl-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-xl-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-xl-offset-9 {
    margin-left: 75%;
  }

  .col-xl-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-xl-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-xl-offset-6 {
    margin-left: 50%;
  }

  .col-xl-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-xl-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-xl-offset-3 {
    margin-left: 25%;
  }

  .col-xl-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-xl-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-xl-offset-0 {
    margin-left: 0;
  }

  .visible-xl {
    display: block !important;
  }

  table.visible-xl {
    display: table;
  }

  tr.visible-xl {
    display: table-row !important;
  }

  th.visible-xl,
  td.visible-xl {
    display: table-cell !important;
  }

  .visible-xl-block {
    display: block !important;
  }

  .visible-xl-inline {
    display: inline !important;
  }

  .visible-xl-inline-block {
    display: inline-block !important;
  }

  .hidden-xl {
    display: none !important;
  }
}
