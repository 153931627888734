// Este é o arquivo para CADASTRO E LOGIN
//
// Tips:
// - Para estilos basicos vá em: BASE.SCSS
// - Para palavras chaves, vá em VARIABLES.SCSS
// - Para animações, vá em ANIMATION.SCSS
// - Para componentes externos, vá em EXTERNAL-COMPONENTES.SCSS
// - A classe .login-page utiliza media-queries em sua árvore e não no final da página
// OBS: os steps e o slider do passo quatro são componentes externos
.sign-in {
  position: relative;
    h5 {
        color: $basic-grey;
        font-size: 14px;
        font-weight: 400;
    }
    span#basic-addon1 {
        background-color: #fff;
    }
}

// fix for form ghost padding
.sign-in form {
    display: inline;
}

span.alternative {
    display: block;
    padding: 10px;
    background: #fff;
    position: absolute;
    margin-top: -43px;
    margin-left: calc( 50% - 35px);
}

a {
    &.forgot-password,
    &.new-account {
        color: $cse-blue;
        font-weight: bold;
        &:hover {
            color: darken($cse-blue, 10%);
            text-decoration: none;
        }
    }
    &.btn-voltar {
        font-weight: 500;
        font-size: 14px;
        padding: 20px 0 0 0;
        color: $basic-grey;
        &:hover {
            text-decoration: none;
            color: $cse-blue;
        }
    }
}

.steps-mobile {
    display: none;
    color: $cse-green;
    ul {
        padding: 0;
        margin-top: 20px;
    }
    li {
        display: inline-block;
        content: " ";
        height: 15px;
        width: 15px;
        background-color: $cse-green;
        border-radius: 50%;
        margin: 0 10px;
        opacity: 0.2;
        &.active {
            opacity: 1;
        }
    }
}

.step-wrap {
    padding: 0;
}

.sign-in-tip {
    margin: 0;
}

.goals-block {
    padding: 10px;
    width: 100%;
    display: block;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    height: 220px;
    text-align: center;
    img {
        width: 50%;
        height: auto;
        margin: 0 auto;
    }
    h3 {
        color: $basic-grey;
        font-size: 18px;
    }
    &.selected {
        background: #eefbfc;
        border: 1px solid $cse-blue;
        h3 {
            color: $cse-blue;
        }
    }
    &:hover {
        border: 1px solid $cse-blue;
    }
}

textarea.goals {
    overflow: hidden;
    -webkit-animation: open-textarea 1s;
    -webkit-animation-fill-mode: forwards;
    animation: open-textarea 1s;
    animation-fill-mode: forwards;
}

//STEP 4
span.interests-tags {
    padding: 2px 0 2px 6px;
    background: $cse-blue;
    border-radius: 2px;
    font-size: 12px;
    color: #fff;
    &::after {
        content: " x ";
        padding: 1px 5px;
        margin-left: 5px;
        background: rgba(0, 0, 0, 0.2);
        display: inline-block;
    }
}

.interests-list {
    width: 100%;
    border: 1px solid #ccc;
    margin-top: -2px;
    background: #fff;
    ul {
        max-height: 70px;
        overflow-x: hidden;
        overflow-y: scroll;
        margin: 0;
        padding: 0;
        li {
            padding: 4px 10px;
            text-align: left;
            color: $basic-grey;
            a {
                font-weight: 400;
                font-size: 24px;
                color: $cse-blue;
                margin-top: -8px;
                &:hover {
                    text-decoration: none;
                }
            }
            &:nth-child(odd) {
                background: $cse-lightgrey;
            }
            img {
                margin-right: -8px;
            }
            a,
            img {
                float: right;
            }
        }
    }
}

.ui-autocomplete.ui-widget.ui-autocomplete-multiple {
    width: 100%;
    span.ui-autocomplete-token-label,
    span.fa {
        color: #fff;
    }
}

.ui-autocomplete-multiple-container.ui-widget.ui-inputtext.ui-state-default.ui-corner-all {
    padding: 0;
    width: 100%;
}

// Esta classe utiliza media-queries em sua árvore e não no final da página
.login-page {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    .auth-panel {
        background-color: $cse-orange;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        .auth-panel-item {
            .auth-title {
                text-align: center;
                color: #fff;
                opacity: 1;
                margin: 0 auto;
                span {
                    text-transform: uppercase;
                    padding: 0;
                }
            }
        }
        &.auth-panel-log {
            .auth-panel-item {
                width: 380px;
                padding: 0;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                .sign-in-tip {
                    text-align: center;
                    padding: 0 20px;
                }
                @media (max-width: $screen-xs-max) {
                    padding-top: 20px;
                }
                &.orange-panel {
                    background: url("/assets/img/social-bulbs.png") no-repeat right bottom;
                    background-size: 320px auto;
                    span {
                        color: #fff;
                    }
                    h3.fixed-padding {
                        padding-top: 6px;
                    }
                }
                &.invite-messages {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    h2 {
                        color: $cse-orange;
                        margin-top: 0;
                    }
                }
                &.redefine-pass {
                    text-align: left;
                    padding: 15px 20px 30px;
                    h3 {
                        color: $cse-orange;
                        font-weight: 400;
                    }
                }
                .auth-cite {
                    font-size: 24px;
                    margin: 50px;
                    color: #fff;
                    p {
                        text-align: center;
                    }
                    p::before,
                    p::after {
                        font-family: FontAwesome;
                        font-size: 54px;
                        opacity: .3;
                    }
                    p::before {
                        content: "\f10d";
                        margin-right: 10px;
                        vertical-align: bottom;
                    }
                    p::after {
                        content: "\f10e";
                        margin-left: 10px;
                        vertical-align: top;
                    }
                }
                &.panel.panel-default.sign-in {
                    padding: 15px 20px 0;
                    margin-bottom: 0 !important;
                }
                .row.text-left.divisor {
                    margin-bottom: 0;
                }
            }
        }
        &.auth-panel-reg {
            width: 1085px;
            height: auto;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            &.not-autorized {
                width: 600px;
                font-size: 18px;
                line-height: 28px;
                img {
                    width: 220px;
                    height: auto;
                }
                .auth-panel-item {
                    .auth-title {
                        color: white;
                        margin: 15px auto;
                    }
                }
            }
            .social-box {
                background-color: #ebf7ff;
                padding: 25px 25px 30px 25px;
                margin-left: 15px;
                display: block;
                border-radius: 5px;
            }
            @media only screen and (max-width: 1140px) {
                width: 100%;
            }
            .auth-panel-item {
                width: 100%;
                .auth-title {
                    color: $cse-green;
                    font-size: 28px;
                    margin: 20px auto 0;
                }
                &.orange-panel {
                    background: url("/assets/img/social-bulbs.png") no-repeat right center;
                    background-size: 240px auto;
                    padding: 8px 0;
                    .new-logo {
                        margin: 0 0 0 30px;
                        display: inline-block;
                    }
                    h3 {
                        font-size: 24px;
                        vertical-align: middle;
                        color: white;
                        display: inline-block;
                        padding-top: 4px;
                        font-weight: normal;
                        &::before {
                            content: "|";
                            display: inline-block;
                            vertical-align: text-top;
                            margin: 0 15px 0 10px;
                        }
                    }
                    @media (max-width: $screen-xs-max) {
                        padding-top: 20px;
                    }
                }
                &.panel.panel-default.sign-in {
                    padding: 15px 30px 30px;
                    margin-bottom: 0 !important;
                }
                &.row.text-left.divisor {
                    margin-bottom: 0;
                }
                #steps {
                    margin-top: 0;
                }
            }
        }
    }
}

// MOBILE STYLES ---------------------------------------------------------------------------------------------------------------------------
@media only screen and (max-width:1366px) {
    .login-page {
        .auth-panel-reg {
            .divisor {
                margin: 20px auto;
            }
            .auth-panel-item.panel.panel-default.sign-in {
                padding: 15px 30px !important;
            }
        }
    }
    .goals-block {
        height: 200px;
        img {
            width: 50%;
            height: auto;
            margin: 0 auto;
        }
    }
}


//768px & lower (only TABLET)
@media only screen and (min-width:480px) and (max-width:768px) {
    .goals-block {
        margin-bottom: 20px;
    }
    .mid-col-center {
        width: 80%;
        margin: 30px auto 0;
    }
}

//768px & lower (SM AND TABLET)
@media only screen and (min-width:321px) and (max-width:768px) {
    .social-box {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 991px) {
    .login-page {
        padding: 0;
        .auth-panel {
            &.auth-panel-reg {
                width: 100% !important;
                .auth-panel-item {
                    &.orange-panel {
                        background: none !important;
                        padding: 8px 0;
                        .new-logo {
                            display: block;
                            margin: 0 auto;
                        }
                        h3 {
                            display: block;
                            margin: 10px auto;
                            text-align: center;
                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }
            &.auth-panel-log {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                .auth-panel-item {
                    padding: 0 20px 20px !important;
                    &.orange-panel {
                        background: none !important;
                    }
                    &.invite-messages {
                        padding-top: 20px !important;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:480px) {
    .login-page {
        .auth-panel-log {
            .auth-panel-item {
                width: 100% !important;
            }
        }
    }
}

//479px & lower (SMARTPHONE)
@media (max-width: $screen-xs-max) {
    #steps {
        display: none;
    }
    .social-box {
        margin-left: 0 !important;
        span {
            font-size: 20px;
        }
    }
    .steps-mobile {
        display: block;
    }
    .sign-in h1 {
        display: none;
    }
    .sign-in-tip {
        display: block;
        margin: 20px 0 0;
    }
    .goals-block {
        margin: 0 auto 20px;
        height: auto;
    }
    .goals-block img {
        width: 70%;
    }
    .step-wrap {
        padding: 0 15px;
    }
    .login-page .auth-panel.auth-panel-log .auth-panel-item {
        padding-top: 20px !important;
    }
    .login-page .auth-panel.auth-panel-log .auth-panel-item.orange-panel .new-logo {
        margin: 10px auto;
    }
}
