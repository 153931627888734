// Este é o arquivo para ESTILOS DO PROFILE DE INFLUENCIADOR
//
// Tips:
// - Para estilos basicos vá em: BASE.SCSS
// - Para palavras chaves, vá em VARIABLES.SCSS
// - Para animações, vá em ANIMATION.SCSS
// - Para componentes externos, vá em EXTERNAL-COMPONENTES.SCSS
.profile.panel {
    color: $basic-grey;
    .panel-heading {
        color: $basic-grey;
        background-color: white;
        border-color: $cse-grey;
        padding: 15px 30px;
    }
    .panel-body {
        padding: 30px;
    }
    ul {
        margin: 0;
        padding: 0;
        width: 100%;
        li {
            display: inline-block;
            margin: 0;
        }
    }
    .social-network-table{
        td{ text-align: center; }
    }
    ul.menu-influencer {
        left: auto;
        right: 0;
        cursor: pointer;
        min-width: auto;
    }
    .influencer-data ul li {
        margin: 5px 20px 0 0;
        cursor: pointer;
        i {
            margin-right: 5px;
        }
    }
    .topo-profile {
        margin-bottom: 20px;
    }
    .audiencia-graficos {
        margin-bottom: 20px;
        h4 {
            font-weight: 500;
            text-transform: uppercase;
        }
    }
    .box-grafico {
        border: 1px solid $cse-grey;
        padding: 15px;
        min-height: 300px;
        .ui-progressbar {
            background: $cse-purple;
            height: 50px;
        }
        .info-genero {
            font-size: 30px;
        }
        .male {
            color: $cse-green;
        }
        .female {
            color: $cse-purple;
        }
        .graf-placeholder {
            width: 100%;
            height: 225px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            background-position: center !important;
            background-repeat: no-repeat !important;
            background-size: contain !important;
            span {
                font-weight: bold;
                text-align: center;
                font-size: 16px;
                padding: 2px 8px;
                background: white;
                border-radius: 4px;
                -webkit-box-shadow: $bs-level-1;
                box-shadow: $bs-level-1;
            }
            &.ph-age-group {
                background: url("/assets/img/img_age-group.jpg");
            }
            &.ph-gender {
                background: url("/assets/img/img_gender.jpg");
            }
            &.ph-language {
                background: url("/assets/img/img_language.jpg");
            }
        }
    }
    ul.informacoes {
        position: relative;
        margin-top: 20px;
        li {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            &.info-box-01 {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                margin-bottom: 10px;
                .ib-title {
                    font-weight: bold;
                }
                span em {
                    overflow-wrap: break-word;
                }
            }
            &.info-box-02 {
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                padding: 2px 10px;
                margin-bottom: 8px;
                border: $cse-grey 1px solid;
                border-radius: 15px;
                i {
                    display: block;
                    width: 16px;
                    text-align: center;
                }
                .ib-title {
                    margin: 0 8px;
                }
                .ib-result {
                    margin-left: auto;
                    text-align: right;
                }
            }
            &.info-box-01.wiki {
                -webkit-box-pack: start;
                -ms-flex-pack: start;
                justify-content: flex-start;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-direction: row;
                flex-direction: row;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                i,
                span {
                    margin-right: 5px;
                }
            }
        }
    }
    ul.contatos {
        li {
            display: block;
            margin-bottom: 15px;
            &::last-child {
                margin-bottom: 0;
            }
        }
        .c-mail {
            word-break: break-all;
        }
        i {
            margin-right: 7px;
        }
        .this-is-a-link {
            i,
            a {
                display: inline-block;
                vertical-align: top;
            }
            a {
                word-break: break-all;
                width: 70%;

            }
        }
    }
    .profile-title {
        span {
            font-size: inherit;
        }
    }
    h4 {
        font-size: 18px;
        font-weight: bold;
    }
    ul.audiencia-list li {
        padding: 2px 8px;
        border-radius: 2px;
        background-color: $cse-orange;
        color: #fff;
        font-size: 13px;
        margin: 0 5px 5px 0;
        i {
            margin-right: 5px;
        }
    }
    ul.interesses-list li {
        padding: 2px 8px;
        border: 1px solid $basic-grey;
        border-radius: 2px;
        color: $basic-grey;
        font-size: 13px;
        margin: 0 5px 7px 0;
    }
    p.bio {
        color: $basic-grey;
        font-style: italic;
        -webkit-overflow: hidden;
        -webkit-text-overflow: ellipsis;
        -moz-overflow: hidden;
        -moz-text-overflow: ellipsis;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 8;
        /* number of lines to show */
        line-height: 1.4;
        /* fallback */
        max-height: 1.4*8;
        /* fallback */
    }
    .step {
        padding: 5px 15px;
        border-radius: 4px;
        color: #fff;
        font-size: 16px;
        text-transform: capitalize;
        i {
            margin-right: 10px;
        }
        &.promoter {
            background: $cse-green;
        }
    }
    .avatar-profile {
        width: 120px;
        min-width: 120px;
        height: 120px;
        display: block;
        border-radius: 50%;
        overflow: hidden;
        margin: 5px auto 0;
        img {
//            position: relative;
            width: 120px;
            height: 120px;
            border-radius: 50%;
        }
    }
    .archetype-icon {
        margin-right: 5px;
        img {
            width: 32px;
            height: 32px;
        }
    }
    .name-profile {
        @include display-inline-middle;
        text-transform: uppercase;
        color: $basic-grey;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        margin: 15px 0;
        width: 100%;
    }
    .statistics-profile,
    .some-more {
        span {
            display: inline-block;
            font-size: 12px;
            font-weight: 300;
            margin: 0 2px;
            vertical-align: top;
            i {
                margin-right: 3px;
            }
        }
        .fmedium,
        span.badge {
            font-size: 14px;
            font-weight: bold;
            color: #fff;
            background: #ccc;
            padding: 4px 8px;
            display: block;
            border-radius: 20px;
            margin: 5px auto 0;
        }
        span.badge {
            background: $cse-blue;
            &:hover {
                text-decoration: none;
                opacity: 0.7;
                cursor: pointer;
            }
        }
    }
    .wrap {
        border-bottom: 1px dotted #ccc;
        i {
            font-size: 14px;
            margin-right: 5px;
        }
        label {
            margin: 5px 5px 5px 0;
            font-size: 14px;
            color: $cse-grey;
        }
    }
    span {
        font-size: 14px;
    }
    .ui-progressbar-value {
        background: $cse-green;
        border-radius: 2px;
    }
    .ui-progressbar {
        background: #f4f4f4;
    }
    .assuntos-unidade:nth-child(1n + 7) {
        display: none;
    }
    .assuntos ul {
        margin-left: -15px;
        li {
            margin-bottom: 10px;
        }
    }
    .ui-progressbar.ui-widget.ui-widget-content.ui-corner-all {
        margin-top: 5px;
    }
    .ladder-stage-plate {
        display: none;
        color: #fff;
        padding: 1px 3px;
        border-radius: 10px;
        position: relative;
        text-rendering: optimizeLegibility;
        margin: -15px auto 0;
        background: $cse-green;
        text-transform: capitalize;
        text-align: center;
    }
    .Unaware .ladder-stage-plate {
        display: block;
        width: 90px;
    }
    .Aware .ladder-stage-plate {
        display: block;
        width: 75px;
    }
    .Interested .ladder-stage-plate {
        display: block;
        width: 90px;
    }
    .Engaged .ladder-stage-plate {
        display: block;
        width: 70px;
    }
    .Advocate .ladder-stage-plate {
        display: block;
        width: 70px;
    }
    .Unaware .avatar-profile,
    .Aware .avatar-profile,
    .Interested .avatar-profile,
    .Engaged .avatar-profile,
    .Advocate .avatar-profile {
        -webkit-box-shadow: 0px 0px 0px 4px rgba($cse-green, 1);
        -moz-box-shadow: 0px 0px 0px 4px rgba($cse-green, 1);
        box-shadow: 0px 0px 0px 4px rgba($cse-green, 1);
    }
    .dts-master {
        display: block;
        margin-bottom: 30px;
        .dts-title {
            display: block;
            position: relative;
            padding-bottom: 5px;
            border-bottom: $cse-grey 1px solid;
            margin-bottom: 15px;
            i {
                color: $cse-grey;
                margin-right: 10px;
            }
        }
    }
}

.profile-button {
    min-width: 200px;
    margin: 0 auto;
}

ul.dados-com-icone {
    display: block;
    margin-bottom: 20px !important;
    i {
        margin-right: 5px;
    }
    li {
        margin-right: 10px !important;
    }
}

.square-profile {
    @include appearing-box;
}

p.label-step {
    text-transform: uppercase;
}

.panel.panel-default.timeline-social {
    margin-top: 60px;
    margin-bottom: 40px;
    padding-top: 0;
    background: url("/assets/img/bg-timeline.png") $cse-lightgrey;
    background-repeat: repeat-y;
    background-position: center;
    border-top: 1px solid #ddd;
    .redes {
        background: $cse-lightgrey;
        padding: 10px;
    }
}

.redes-sociais {
    display: inline-block;
    text-align: center;
    width: 100%;
    margin-bottom: 15px;
}

.social-network-profile {
    display: inline-block;
    vertical-align: middle;
    min-width: 30px;
    text-align: center;
    margin: 0;
    font-size: 14px;
}
.total-metrics-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
}
.reach-total,
.engagement-total {
    padding: 15px 0;
    width: 48%;
    i{
        margin-right: 5px;
    }
    strong{
        font-size: 16px;
        font-weight: 400;
    }
}
.reach-total{ background-color: #f0f7fd; }
.engagement-total{  background-color: #ebfef8;}
.social-network-table{
    table.table th{ border-top:0;}
    tr.reach-tr{ background-color: #f0f7fd;}
    tr.engagement-tr{ background-color: #ebfef8;}
}

.chart-container-1 {
    margin: 0 auto;
    display: block;
}

.chart-container-1{
    canvas.chartjs-render-monitor{
        height: 250px;
    }
}

// TIMELINE ====================================
.timeline {
    //general
    display: block;
    margin: 40px auto 0;
    .start {
        // before true timeline
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 4px solid $cse-grey;
        background: $cse-lightgrey;
        content: " ";
        display: block;
        margin: 0 auto;
    }
    .theline {
        // before true timeline
        width: 1px;
        min-height: 50px;
        border-left: 4px dotted $cse-grey;
        content: " ";
        display: block;
        margin: 0 auto;
    }
}

.timeline-social .page-flip {
    position: relative; // the tru timeline
    ul.nav.nav-tabs {
        margin-top: -58px;
        .nav-link {
            border-radius: 0;
            padding: 10px 30px;
            border-bottom: 0;
            font-size: 16px;
            color: $basic-grey;
            &:hover span {
                color: $cse-blue;
            }
            &:hover {
                background: transparent;
            }
            &.active {
                background: $cse-lightgrey;
            }
            &.active:hover {
                background: $cse-lightgrey;
            }
        }
    }
    .activity-tabs ul.nav.nav-tabs {
        margin-top: 0;
        position: relative;
        .nav-link {
            border-radius: 4px;
            border: 0;
            padding: 5px;
            margin-right: 5px;
        }
    }
}

// social timeline
.timeline-mini-alert {
    // "the last update was..."
    background: $note-yellow;
    border-radius: 10px;
    color: $basic-grey;
    display: block;
    margin: 10px auto 0;
    width: 570px;
    padding: 4px 10px;
    font-size: 13px;
    a {
        cursor: pointer;
    }
}

.date-break {
    margin: 20px 0;
    .badge {
        min-width: 120px;
        font-size: 16px;
        background: $cse-bluegrey;
    }
}

#columns {
    position: relative;
    -webkit-column-gap: 60px;
    -webkit-column-rule: 4px dotted transparent;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    -moz-column-count: 2;
    -moz-column-gap: 60px;
    -moz-column-rule: 4px dotted transparent;
    column-count: 2;
    -webkit-column-gap: 60px;
    column-gap: 60px;
    column-rule: 4px dotted transparent;
    -webkit-column-rule-width: 4px;
    column-rule-width: 4px;
    -moz-column-rule-width: 4px;
    max-width: 1000px;
    margin: 0 auto;
}

.prevent-shadow-break {
    display: block;
    padding: 5px 0 10px;
    -webkit-column-break-inside: avoid;
    break-inside: avoid;
}

.tab-pane {
    .action {
        margin-top: 20px;
    }
}

.timeline-block {
    -webkit-box-shadow: $bs-level-1-b;
    box-shadow: $bs-level-1-b;
    background: #fff;
    border-radius: 0;
    padding: 15px;
    break-inside: avoid;
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
    margin: 0;
    &.note {
        display: inline-block;
        background: $note-yellow;
        width: 100%;
    }
    &.action {
        padding: 30px;
    }
    h1.ativity-title {
        display: none;
    }
    hr {
        display: none;
    }
    span.likes,
    span.likes span {
        font-size: 12px;
        white-space: nowrap;
        i {
            font-size: 12px;
            margin-right: 5px;
        }
    }
    span.likes {
        &:nth-child(1) {
            margin-right: 20px;
        }
    }
    &.ativity {
        display: inline-block;
        width: 100%;
        hr {
            display: block;
        }
        h3 {
            margin-top: 5px;
        }
        h3,
        .description-atividade {
            width: 100%;
            overflow: hidden;
        }
    }
    &.ativity.activity_marked {
        @include marked;
        border: 1px solid rgba(52, 152, 219, 0);
    }
    &.action-ativities {
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px dashed #ccc;
        margin: 30px 0;
        padding: 20px;
        h2 {
            margin: 0 0 20px 0;
            color: $cse-grey;
            font-weight: 400;
        }
    }
    a.action-links {
        margin: 20px auto;
        display: block;
        font-weight: 500;
        text-align: center;
    }
    a.action-links,
    a.action-links i {
        font-size: 24px;
        color: $cse-blue;
    }
    &.timeline-grid {
        max-width: 1000px;
        margin: 0 auto;
        &.note {
            display: block;
        }
    }
    &.modal-activity {
        padding: 30px;
    }
    i {
        font-size: 20px;
        color: $basic-grey;
        margin-right: 10px;
    }
    .net i {
        margin: 0;
    }
    span.closeit a {
        color: $cse-blue;
        opacity: 0.8;
        &:hover {
            opacity: 0.4;
        }
    }
    .kind-of-ativity {
        width: 22px;
        height: 22px;
        border: 1px solid $cse-grey;
        background-color: #f6f6f6;
        border-radius: 50%;
        padding-top: 5px;
        text-align: center;
        margin-right: 5px;
        display: inline-block;
        i {
            font-size: 12px;
            margin: 0;
            display: block;
            float: none;
        }
    }
    .menu-atividades a i {
        font-size: 16px;
        color: #ccc;
        cursor: pointer;
    }
    .menu-atividades a:hover i {
        color: $cse-blue;
    }
    &.instagram {
        .embed-responsive {
            position: relative;
            height: 0;
            overflow: hidden;
            iframe,
            object,
            embed {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 130%;
                overflow: hidden;
            }
        }
        .foto-description {
            display: none;
        }
    }
    &.social {
        display: inline-block;
        width: 100%;
    }
    &.social:before {
        position: absolute;
        float: right;
        width: 20px;
        height: 20px;
        content: " ";
        border: 4px solid $cse-lightgrey;
        border-radius: 50%;
        background-color: $cse-bluegrey;
        right: calc(50% - 10px);
        margin-top: -10px;
    }
    &.youtube {
        .timeline-photo {
            height: 300px;
        }
    }
    .activity-owner {
        @include truncate;
        display: inline-block;
        vertical-align: middle;
    }
}

a.view-more {
    margin: 0 5px;
    font-size: 12px;
    color: $cse-blue;
    cursor: pointer;
}


.timeline-content {
    position: relative;
    a.direct-link-to-social-network {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
    }
    .video-instagram-16by9 {
        padding-bottom: calc(56.25% + 64px);
    }

    .video-instagram-5by4 {
        padding-bottom: calc(125% + 64px);
    }

    .video-instagram-1by1 {
        padding-bottom: calc(100% + 64px);
    }

    .video-16by9 {
        padding-bottom: 56.25%;
        height: 0;
    }

    .video-5by4 {
        padding-bottom: 125%;
        height: 0;
    }

    .video-1by1 {
        padding-bottom: 100%;
        height: 0;
    }
    .btn-interact {
        font-size: 14px;
        padding: 2px 6px;
        border-radius: 3px;
        i {
            font-size: 14px;
            color: white;
            margin-right: 6px;
        }
    }
}

.ghost-timeline-block {
    opacity: 0;
    display: none;
}

.net {
    position: relative;
    display: inline-block;
    text-align: right;
}

.timeline-day:nth-child(2n) .one-only .ghost-timeline-block {
    display: block;
}

.timeline-title {
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

.timeline-title,
.timeline-link,
.timeline-photo,
.titulo-video-youtube,
.foto-description {
    width: 100%;
    display: block;
    margin: 0 0 15px;
}

.youtube .timeline-title {
    font-weight: bold;
}

.trying-to-fit {
    // title of the post calc
    @include truncate;
    width: calc(100% - 190px);
    vertical-align: top;
}

.timeline-link {
    @include truncate;
    display: block;
    padding: 2px 5px;
    border: 1px solid #f4f4f4;
    border-radius: 2px;
    position: relative;
    i {
        margin-right: 5px;
        float: none;
        font-size: 14px;
        color: $cse-grey;
    }
    a {
        color: $cse-blue;
    }
    &:hover {
        border: 1px solid $cse-blue;
        cursor: pointer;
        i {
            color: $cse-blue;
        }
    }
}

.timeline-video-thumb i {
    top: 50%;
    position: absolute;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 4.5em;
    color: #fff;
    cursor: pointer;
    opacity: 0.8;
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
    margin-right: 0;
}

.timeline-photo {
    width: 100%;
    height: auto;
    text-align: center;
    overflow: hidden;
    background: #f4f4f4;
    img {
        margin: 0 auto;
    }
}

.foto-description {
    // photo feed description
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    /* number of lines to show */
    line-height: 1.2;
    /* fallback */
    max-height: 1.2*6;
    /* fallback */
}

feed-photo:nth-child(1n + 2) {
    display: inline-block;
    width: 18%;
    margin-right: 2%;
    vertical-align: top;
    .timeline-photo {
        max-height: 60px;
    }
}

.timeline-block.multiplas-fotos {
    .timeline-photo::nth-child(n + 2) {
        display: none;
    }
}

.timeline-block {
    .titulo-video-youtube .timeline-title {
        display: none;
    }
    &.youtube {
        .timeline-title {
            display: none;
        }
        .titulo-video-youtube .timeline-title {
            display: block;
        }
    }
}

// ativities timeline
.text-notes {
    display: block;
    font-size: 14px;
    font-style: italic;
    margin: 10px 0;
}

.title-notes {
    text-transform: capitalize;
}

a.uncheck-button-ativities i {
    color: $cse-green;
    &:hover {
        opacity: 0.7;
    }
}

a.check-button-ativities i {
    color: $basic-grey;
    &:hover {
        color: $cse-green;
    }
}

a.pin-note i {
    color: $cse-blue;
    &:hover {
        opacity: 0.7;
    }
}

a.pin-note.unpinned i {
    -ms-transform: rotate(-45deg);
    /* IE 9 */
    -webkit-transform: rotate(-45deg);
    /* Chrome, Safari, Opera */
    transform: rotate(-45deg);
}

.dropdown-menu.right-side {
    margin-left: -150px
}

a.menu-ativity-button i {
    font-size: 14px;
    color: $cse-blue;
    &:hover {
        opacity: 0.7;
    }
}

a.menu-ativity-button {
    margin: 0;
}

.ativity-note-profile {
    padding: 5px 10px;
    border-radius: 4px;
    background: $note-yellow;
    color: $basic-grey;
    font-size: 13px;
    margin: 15px 15px 0;
    width: auto;
}

.data-atividade span {
    font-size: 12px;
    font-style: italic;
}

textarea.note {
    background: $note-yellow;
}

.tab-link i {
    margin-right: 5px;
}

.top-block.action .row.activity-row {
    margin: 0;
}

.profile-flag.badge {
    line-height: 1.4;
}

.conclusion .btn {
    i {
        font-size: inherit;
        color: inherit;
        margin: 0;
    }
    &.btn-default {
        border: 1px solid #ccc;
        i {
            color: $cse-blue;
        }
    }
    &.disabled {
        cursor: default;
    }
}

.timeline-footer {
    display: none;
}

a.links-sociais {
    font-size: 14px;
    display: block;
    color: $basic-grey;
    i {
        font-size: 12px;
    }
}

.menu-influencer {
    font-size: 14px;
    font-weight: bold;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto 0;
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
    a.dropdown-toggle {
        color: #fff;
        margin: 0 auto;
        display: block;
    }
    &.open .dropdown-toggle {
        box-shadow: none;
        -webkit-box-shadow: none;
    }
    ul.dropdown-menu {
        width: 100px;
        li {
            display: block;
            width: 100%;
            &:last-child {
                margin-bottom: 5px !important;
            }
        }
    }
}

.fc-view-container,
.fc-view-container * {
    z-index: 0;
}

.loading-message {
    background: #fff;
    padding: 6px 9px;
    border-radius: 4px;
    color: $cse-blue;
    font-weight: bold;
    position: relative;
    width: auto;
    margin: -200px auto 0;
    margin-left: calc(50% - 185px);
    box-shadow: $bs-level-1-b;
}

.lista-de-campanhas {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    li {
        margin: 4px !important;
    }
}

.block-only-members {
    width: 100%;
    padding: 15px;
    background-color: $note-yellow;
    text-align: center;
    font-weight: 700;
}

.panel-cse{
    padding: 30px 40px;
}

.blue-panel{
    padding: 15px 20px;
    background: $cse-lightblue;
    font-size: 16px;
}

.social-network-table{
    table tr td {  
      white-space: nowrap;
      overflow: hidden;
    }
}

//// MOBILE===============================================================================================================================
@media (max-width:$screen-xs-max) {
    .profile.panel {
        .box-grafico {
            min-height: unset;
        }
    }
}

@media (max-width: $screen-sm-max) {
    .profile.panel {
        background: #fff;
        padding: 0;
        .sm-mb20 {
            margin-bottom: 20px;
        }
        .panel-body {
            padding: 20px;
            .topo-profile {
                .row.mg0 {
                    text-align: center;
                    .btn.btn-primary.w100 {
                        max-width: 260px;
                    }
                }
            }
        }
        .avatar-profile {
            overflow: -webkit-paged-x;
            overflow: -webkit-paged-y;
        }
    }
    .timeline-footer {
        display: none;
    }
    #columns {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        column-gap: 0;
    }
    .timeline-block:before {
        display: none;
    }
    .timeline-photo,
    .trying-to-fit {
        width: 100%;
    }
    .timeline-mini-alert {
        width: auto;
    }
    .top-block.action {
        margin: 0 -30px;
        padding: 10px;
    }
    .timeline-social .page-flip .top-block .ativity-ownership {
        display: none;
    }
    .timeline-social .page-flip .top-block .activity-content {
        min-height: 550px;
        max-height: 550px;
    }
    .redes {
        z-index: auto !important;
    }
    .panel.timeline-social {
        margin-bottom: 30px;
        padding-right: 0;
        padding-left: 0;
    }
    .timeline-block {
        padding-bottom: 5px;
        .net {
            margin-top: 15px;
            text-align: center;
        }
        &.modal-activity {
            padding: 30px 15px;
            .ativity-ownership {
                margin-bottom: 20px;
            }
            textarea.form-control {
                height: 80px;
                min-height: 80px !important;
            }
        }
    }
    .box-grafico {
        margin-bottom: 10px;
        :last-child {
            margin-bottom: 0;
        }
    }
}

@media (max-width: $screen-md-max) {
    .box-grafico {
        margin-bottom: 20px;
        :last-child {
            margin-bottom: 0;
        }
    }
    .loading-message {
        display: none;
    }
}
