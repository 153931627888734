// Este é o arquivo para COMPONENTES EXTERNOS
//
// Tips:
// - Para estilos basicos vá em: BASE.SCSS
// - Para palavras chaves, vá em VARIABLES.SCSS
// - Para animações, vá em ANIMATION.SCSS
//===================================================================================

// PRIMENG 11
.p-component {
  font-family: "Roboto", sans-serif;
}

.p-component * {
  font-size: 14px;
}

// REMOVER BORDA DE FOCUS
.p-button:focus {
  box-shadow: none;
}

// CONFIRM POPUP
.delete-confirm-popup {

  .p-confirm-popup-reject {
    background: transparent;
    border-color: transparent;
    color: #dc3545;

    &:enabled:hover {
      background: rgba(220, 53, 69, 0.1);
      color: #dc3545;
    }
  }

  .p-confirm-popup-accept {
    background: #dc3545;
    border-color: #dc3545;

    &:hover {
      background: #b02a37;
      border-color: #b02a37;
    }
  }
}

.p-confirm-popup:after, .p-confirm-popup:before {
  display: none;
}

// REMOVE INPUT BORDER
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border: none;
}


// P-DATATABLE
.p-datatable {
  .p-datatable-header {
    background: transparent;
    padding: 2rem 0;
    border: none;
  }
  .p-datatable-thead {
    background-color: #f4f4f4;
  }
  .p-datatable-thead > tr > th {
    background-color: unset;
    border-bottom: none;
  }

  .p-paginator-bottom {
    padding: 2rem;
  }

  .p-datatable-footer {
    text-align: center;
  }

  tbody, td, tfoot, th, thead, tr {
    border-width: 1px;
    border-color: #dee2e6;
  }
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: #f4f4f4;
}

// PAGINAÇÃO

.p-paginator {
  button {
    width: 32px;
    height: 32px !important;
  }
  .p-dropdown {
    height: 32px;

    .p-dropdown-items {
      padding-left: 0;
    }
  }
}

// MULTISELECT
.p-multiselect {
  .p-multiselect-label.p-placeholder {
    color: $basic-grey;
  }
}

.p-multiselect-panel {
  .p-multiselect-items {
    padding-left: 0;
    .p-multiselect-item.p-highlight{
      color: #212529;
      background: unset;
    }
  }
}

// DROPDOWN
.p-dropdown {
  .p-dropdown-items {
    padding-left: 0;
    margin-bottom: 0;
  }
}

.p-dropdown-panel .p-dropdown-items {
  padding-left: 0;
  margin-bottom: 0;
}

// TREE CONTAINER
.p-tree-container {
  padding-left: 0;
}

// CONTEXT MENU
.p-contextmenu-root-list {
  padding-left: 0;
  margin-bottom: 0;

  a {
    text-decoration: none;
  }

}

  // CHECKBOX
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #399adc !important;
  background: #399adc;
}

// P-SLIDER
.p-slider.p-slider-horizontal {
  height: 4px;
}

.p-slider .p-slider-handle {
  height: 1rem;
  width: 1rem;
  border-radius: 100%;
}

// P-SELECTBUTTON

.p-selectbutton .p-button {
  background: #fff;
  border: 1px solid #dedede;
  color: #999;

  &:hover {
    background: #dedede !important;
    border-color: #dedede !important;
    color: #999 !important;
  }
}

.p-selectbutton .p-button.p-highlight {
  background: #dedede;
  border-color: #dedede;
  color: #999;
}

// P-TREE
.p-tree {
  border: none;
}

// P-TABVIEW
.p-tabview {
  .p-tabview-panels {
    border: 1px solid #ddd;
    border-top: 0;
    box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 10%);
    background-color: #fff;
  }

  .p-tabview-nav {
    margin-bottom: 0;

    li {
      .p-tabview-nav-link {
        font-weight: normal;
        color: #3498db;
        background-color: transparent;
        border-color: transparent;
        padding: 15px;
        text-decoration: none;
      }

      &.p-highlight .p-tabview-nav-link {
        background: #ffffff;
        border-color: #ddd #ddd #ffffff #ddd;
        color: #495057;
      }
    }
  }
}

// CHIPS
.p-chips {
  width: 100%;
}

.p-chips > ul.p-inputtext {
  padding: 14px;
  width: 100%;
}

.p-chips-token {
  padding: 8px !important;
  font-size: 14px;
  border-radius: 100px;
  background-color: #3498db;
  color: #fff;
}

.p-chips-input-token input {
  font-size: 16px !important;
}

// SIDEBAR
.p-sidebar {
  .p-sidebar-header {
    .p-sidebar-close {
      margin-left: auto;
    }
  }

  &.cse-sidebar {
    width: 460px;
    max-width: 100%;
  }
}

// BS Datepicker
.bs-datepicker-body table td.week span
{
  color: #3498db !important;
}

.bs-datepicker-body table td span.selected,
.bs-datepicker-head,
.bs-datepicker-head, .bs-datepicker button:active,
.bs-datepicker-body table td.selected span,
.bs-datepicker-body table td span[class*="select-"]:after,
.bs-datepicker-body table td[class*="select-"] span:after,
.bs-datepicker-body table td.active-week span:hover
{
  background-color: #3498db !important;
}

// NG-PRIME SLIDER
.ui-slider.ui-widget.ui-widget-content.ui-corner-all.ui-slider-horizontal {
  height: 8px;
}

.ui-slider-handle.ui-state-default.ui-corner-all {
  height: 15px;
  width: 15px;
  top: -4px !important;
  margin-left: -5px;
}

// STEPS DO CADASTRO
.bs-wizard {
  margin-top: 40px;
}

.bs-wizard {
  border-bottom: 0;
  padding: 0 0 10px 0;
}

.bs-wizard > .bs-wizard-step {
  padding: 0;
  position: relative;
}

.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
  color: #ccc;
  font-size: 16px;
  margin-bottom: 2px;
}

.bs-wizard > .bs-wizard-step .bs-wizard-info {
  color: #ccc;
  font-size: 14px;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
  position: absolute;
  width: 38px;
  height: 38px;
  display: block;
  background: $cse-green;
  top: 45px;
  left: 50%;
  margin-top: -42px;
  margin-left: -15px;
  border-radius: 50%;
  border: 6px solid #fff;
}

.bs-wizard > .bs-wizard-step > .progress {
  position: relative;
  border-radius: 0px;
  height: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 20px 0;
}

.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
  width: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: $cse-green;
}

.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {
  width: 100%;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
}

.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
  width: 50%;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
  -webkit-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
}

.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {
  width: 0%;
}

.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {
  width: 100%;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {
  background-color: $cse-bg;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot::after {
  opacity: 0;
}

.bs-wizard > .bs-wizard-step:first-child > .progress {
  left: 50%;
  width: 50%;
}

.bs-wizard > .bs-wizard-step:last-child > .progress {
  width: 50%;
}

.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot {
  pointer-events: none;
}

.bs-wizard > .bs-wizard-step.complete h5.bs-wizard-stepnum,
.bs-wizard > .bs-wizard-step.active h5.bs-wizard-stepnum {
  color: $cse-green;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot span {
  color: #fff;
  margin-top: 5px;
  font-size: 13px;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot span {
  color: #ccc;
}

// DROP DOWN
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

// SCROLL BAR
.ps-container {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps-container {
    overflow: auto !important;
  }
}

.ps-container.ps-active-x > .ps-scrollbar-x-rail,
.ps-container.ps-active-y > .ps-scrollbar-y-rail {
  display: block;
  background-color: transparent;
}

.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color: #999;
  height: 11px;
}

.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: #999;
  width: 11px;
}

.ps-container > .ps-scrollbar-x-rail {
  display: none;
  position: absolute;
  opacity: 0;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  -o-transition: background-color 0.2s linear, opacity 0.2s linear;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  bottom: 0px;
  height: 15px;
}

.ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  position: absolute;
  background-color: #aaa;
  border-radius: 6px;
  -webkit-transition: background-color 0.2s linear, height 0.2s linear,
    width 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out;
  -o-transition: background-color 0.2s linear, height 0.2s linear,
    width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s linear,
    width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  transition: background-color 0.2s linear, height 0.2s linear,
    width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  bottom: 2px;
  height: 6px;
}

.ps-container > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x,
.ps-container > .ps-scrollbar-x-rail:active > .ps-scrollbar-x {
  height: 11px;
}

.ps-container > .ps-scrollbar-y-rail {
  display: none;
  position: absolute;
  opacity: 0;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  -o-transition: background-color 0.2s linear, opacity 0.2s linear;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  right: 0;
  width: 15px;
}

.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  position: absolute;
  background-color: #aaa;
  border-radius: 6px;
  -webkit-transition: background-color 0.2s linear, height 0.2s linear,
    width 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out;
  -o-transition: background-color 0.2s linear, height 0.2s linear,
    width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s linear,
    width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  -o-transition: background-color 0.2s linear, height 0.2s linear,
    width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  transition: background-color 0.2s linear, height 0.2s linear,
    width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  right: 2px;
  width: 6px;
}

.ps-container > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y,
.ps-container > .ps-scrollbar-y-rail:active > .ps-scrollbar-y {
  width: 11px;
}

.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container:hover.ps-in-scrolling.ps-x
  > .ps-scrollbar-x-rail
  > .ps-scrollbar-x {
  background-color: #999;
  height: 11px;
}

.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container:hover.ps-in-scrolling.ps-y
  > .ps-scrollbar-y-rail
  > .ps-scrollbar-y {
  background-color: #999;
  width: 11px;
}

.ps-container:hover > .ps-scrollbar-x-rail,
.ps-container:hover > .ps-scrollbar-y-rail {
  opacity: 0.6;
}

.ps-container:hover > .ps-scrollbar-x-rail:hover {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
  background-color: #999;
}

.ps-container:hover > .ps-scrollbar-y-rail:hover {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
  background-color: #999;
}

.ps-container .ps-content {
  min-height: 95% !important;
}

// AUTO complete
p-autoComplete {
  .p-autocomplete-input {
    &:focus {
      border: 1px solid #ced4da !important;
    }
  }
}


.search-autocomplete {
  .suggestions,
  .drop-down-menu {
    border: solid 1px #f1f1f1;
    position: absolute;
    min-width: 300px;
    width: auto;
    background: white;
    z-index: 99999;
    padding: 10px;
    left: -8px;
    top: 25px;
    border: 1px solid #c7c7c7;
    -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    border-radius: 0.25em;
    label {
      margin: 5px 0 !important;
    }
    ul {
      padding: 0px;
      margin: 0px;
      li {
        list-style: none;
        padding: 0px;
        margin: 0px;
        a {
          padding: 5px;
          display: block;
          text-decoration: none;
          color: $cse-blue;
          cursor: pointer;
        }
      }
    }
    .avatarz {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      text-align: center;
      overflow: hidden;
      padding: 0;
      margin: 0;
      display: block;
    }
    .avatarz img {
      height: 120%;
    }
    .sug-inf {
      margin: 10px 0;
    }
  }
  .container {
    width: 600px;
    margin-left: 10px;
    margin-top: 10px;
  }
  .complete-selected {
    background-color: #f7f7f7;
    font-weight: 500;
  }
  .auto-complete-header {
    width: 100%;
    color: $cse-grey;
    margin: 0;
    padding: 2px 5px;
    background: #fff;
    border-bottom: 1px solid $cse-grey;
  }
  .auto-complete-nosuggestion {
    font-size: 14px;
    font-style: italic;
    color: #ccc;
  }
}

.ui-autocomplete-input-token input {
  border: 0 !important;
  &:focus {
    outline: none;
  }
}

// AGENDA DE ATIVIDADES
.calendar {
  .fc-view-container {
    height: 200px !important;
  }
  .fc-header-toolbar {
    text-align: left;
    margin: 0;
    h2 {
      font-size: 18px;
      color: $cse-grey;
    }
  }
  .ui-state-active,
  .ui-state-highlight {
    background: #f9f9f9;
  }
  span.fc-title,
  span.ui-icon {
    color: #fff;
  }
  .ui-button {
    background: $cse-blue;
  }
  .fc .fc-event {
    background: rgba(238, 117, 70, 0.5);
    border: 0;
    padding: 2px 10px;
    font-size: 12px;
  }
  .fc-scroller.fc-time-grid-container {
    height: calc(100vh - 720px);
  }
  .fc-time-grid-event .fc-time span {
    color: #fff;
  }
  &.edit {
    .fc-right,
    .fc-left {
      display: none;
    }
  }
}

// DATATABLE- TELA DE INSERÇÃO DE ARQUÉTIPOS
.arq-table {
  background-color: white;
  color: $basic-grey;
  font-family: "Roboto", sans-serif !important;
  width: 100%;
  table {
    width: 100%;
  }
  & * {
    border: none !important;
  }
  thead {
    tr {
      background-color: $cse-lightgrey;
      font-size: 16px;
      border-top: 1px solid #ddd !important;
      border-bottom: 1px solid #ddd !important;
      th {
        background-color: $cse-lightgrey !important;
        color: $basic-grey !important;
        text-align: left;
        font-weight: bold !important;
        &.ui-state-active {
          background-color: darken($cse-lightgrey, 4%) !important;
        }
        &:nth-child(1),
        &:nth-child(5) {
          width: 5%;
          text-align: center;
        }
        &:nth-child(2) {
          width: 15%;
        }
        &:nth-child(3),
        &:nth-child(4) {
          width: 37.5%;
        }
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #ddd !important;
      &.ui-datatable-even {
        background-color: white !important;
      }
      td {
        img {
          width: 32px;
          height: 32px;
          margin: 0 auto;
        }
        &:nth-child(1),
        &:nth-child(5) {
          text-align: center;
        }
        &:nth-child(2) {
          font-weight: bold;
        }
        .arq-action {
          i {
            font-size: 18px;
          }
          cursor: pointer;
          &:first-child {
            margin-right: 6px;
            position: relative;
            bottom: -1.6px;
          }
        }
      }
    }
  }
}
// UPDATE ANGULAR GRID UI TABLE
#grid {
  ::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: $cse-grey;
  }

  body {
    &::-webkit-scrollbar-thumb {
      background-color: $basic-grey;
    }
  }

  .ui-multiselect-item.ui-state-highlight {
    background: #fff;
    .ui-chkbox-icon::before {
      color: #fff;
    }
    .ui-chkbox {
      border: 0;
    }
  }
  .ui-widget-header {
    border: 1px solid $cse-bg;
  }

  .ui-widget {
    font-family: $basic-font;
    color: #666;
  }
  span.ui-clickable.fa-caret-down {
    margin-top: 6px;
  }
  input.form-control {
    padding: 0.5rem 0.75rem;
    height: 36px;
    border-radius: 4px;
    font-weight: normal;
    box-shadow: none;

    &::placeholder {
      color: #6c757d;
      font-weight: normal;
      font-style: normal;
    }
  }
  .maps {
    @extend .form-control;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
    height: 32px;
    box-shadow: 0;
    font-weight: 500;
    .search-autocomplete {
      width: 100%;
    }
    i {
      color: #ccc;
      opacity: 0.5;
    }

    input#search {
      border: 0;
      width: calc(100% - 20px);
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #6c757d;
        font-weight: normal;
        font-style: normal;
      }
    }
  }
  span.tag {
    font-size: 13px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    padding: 4px;
    border-radius: 3px;
    background: $cse-orange;
    color: #fff;
    margin-top: 4px;
    span {
      display: block;
    }
    i {
      display: block;
    }
  }
  .search-autocomplete {
    .suggestions {
      position: relative;
      min-width: 100%;
      width: auto;
      left: -5px;
      top: 0;
      font-weight: 500;
    }
  }
  .ui-multiselect {
    padding: 2px 5px;
  }

  label.ui-multiselect-label {
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
    margin: 0;
    font-size: 14px;
  }
  .ui-table {
    position: relative;
    background: #fff;
  }

  .ui-table table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }

  .ui-table .ui-table-thead > tr > th,
  .ui-table .ui-table-tbody > tr > td,
  .ui-table .ui-table-tfoot > tr > td {
    padding: 0.4em 0.6em;
    border: 1px solid $cse-bg;
    line-break: strict;
  }
  .ui-table .ui-table-thead > tr > th.ui-sortable-column {
    font-weight: 600;
    opacity: 0.9;
  }

  .ui-table .ui-table-tbody > tr:hover {
    background: #f2fbfe;
  }

  .ui-table .ui-table-thead > tr {
    background: #f2fbfe;
  }

  .ui-table .ui-table-thead > tr > th {
    border: 1px solid $cse-bg;
  }

  .ui-table .ui-sortable-column {
    cursor: pointer;
  }

  .ui-table-auto-layout > .ui-table-wrapper {
    overflow-x: auto;
  }

  .ui-table-auto-layout > .ui-table-wrapper > table {
    table-layout: fixed;
  }

  /* Sections */
  .ui-table-caption,
  .ui-table-summary {
    padding: 5px 0;
    text-align: center;
    font-weight: bold;
    background: $cse-bg;
  }

  .ui-table-caption {
    border-bottom: none;
  }

  .ui-table-caption {
    border-bottom: 0 none;
  }

  .ui-table-summary {
    border-top: 0 none;
  }

  /* Paginator */
  .ui-table .ui-paginator-top {
    border-bottom: 0 none;
  }

  .ui-table .ui-paginator-bottom {
    border-top: 0 none;
  }

  /* Scrollable */
  .ui-table-scrollable-wrapper {
    position: relative;
  }
  .ui-table-scrollable-header,
  .ui-table-scrollable-footer {
    overflow: hidden;
    border: 0 none;
  }
  .ui-table-scrollable-body {
    overflow: auto;
    position: relative;
  }

  .ui-table-scrollable-body > table > .ui-table-tbody > tr:first-child > td {
    border-top: 0 none;
  }

  .ui-table-virtual-table {
    position: absolute;
  }

  /* Frozen Columns */
  .ui-table-frozen-view .ui-table-scrollable-body {
    overflow: hidden;
  }

  .ui-table-frozen-view
    > .ui-table-scrollable-body
    > table
    > .ui-table-tbody
    > tr
    > td:last-child {
    border-right: 0 none;
  }

  .ui-table-unfrozen-view {
    position: absolute;
    top: 0px;
  }

  /* Resizable */
  .ui-table-resizable > .ui-table-wrapper {
    overflow-x: auto;
  }

  .ui-table-resizable .ui-table-thead > tr > th,
  .ui-table-resizable .ui-table-tfoot > tr > td,
  .ui-table-resizable .ui-table-data > tr > td {
    overflow: hidden;
  }

  .ui-resizable-column {
    background-clip: padding-box;
    position: relative;
  }

  .ui-table-resizable-fit .ui-resizable-column:last-child .ui-column-resizer {
    display: none;
  }

  .ui-table .ui-column-resizer {
    display: block;
    position: absolute !important;
    top: 0;
    right: 0;
    margin: 0;
    width: 0.5em;
    height: 100%;
    padding: 0px;
    cursor: col-resize;
    border: 1px solid transparent;
  }

  .ui-table .ui-column-resizer-helper {
    width: 1px;
    position: absolute;
    z-index: 10;
    display: none;
  }

  /* Edit */
  .ui-table .ui-table-tbody > tr > td.ui-editing-cell {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ui-table-wrapper {
    border: 1px solid $cse-bg;
  }

  .ui-table .ui-table-tbody > tr > td.ui-editing-cell p-celleditor > * {
    width: 100%;
  }

  /* Reorder */
  .ui-table-reorder-indicator-up,
  .ui-table-reorder-indicator-down {
    position: absolute;
    display: none;
  }

  /* Responsive */
  .ui-table-responsive .ui-table-tbody > tr > td .ui-column-title {
    display: none;
  }
  @media screen and (max-width: 1440px) {
    .col-group-section {
      width: 200px;
    }
  }
  @media screen and (max-width: 40em) {
    .ui-table-responsive .ui-table-thead > tr > th,
    .ui-table-responsive .ui-table-tfoot > tr > td {
      display: none !important;
    }

    .ui-table-responsive .ui-table-tbody > tr > td {
      text-align: left;
      display: block;
      border: 0 none;
      width: 100% !important;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      float: left;
      clear: left;
    }

    .ui-table-responsive .ui-table-tbody > tr > td .ui-column-title {
      padding: 0.4em;
      min-width: 30%;
      display: inline-block;
      margin: -0.4em 1em -0.4em -0.4em;
      font-weight: bold;
    }
  }

  /* Loader */
  .ui-table-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
    opacity: 0.1;
    z-index: 1;
  }

  .ui-table-loading-content {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    margin-top: -1em;
    margin-left: -1em;
  }
  .tct-field {
    @include truncate;
    display: block;
  }
}

.ui-paginator {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0 !important;
  .ui-paginator-pages {
    .ui-paginator-page {
      line-height: 10px;
    }
  }
}

.p-tooltip {
  .p-tooltip-text {
    background-color: $cse-orange !important;
  }

  &.p-tooltip-top .p-tooltip-arrow {
    border-top-color: $cse-orange !important;
  }

  &.p-tooltip-left .p-tooltip-arrow {
    border-left-color: $cse-orange !important;
  }

  &.p-tooltip-right .p-tooltip-arrow {
    border-right-color: $cse-orange !important;
  }

  &.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: $cse-orange !important;
  }
}


// TIMEPICKER ATIVIDADES

@media only screen and (max-width: 480px) {
  modal-relationship-add-activity {
    .ui-datepicker {
      display: none !important; // Não é a melhor solução mas fui aconselhada a colocar assim por hora diante do trabalho que ia demandar mexer nesse componente.
    }
  }
}
