// Este é o arquivo para LISTAS OS SCSS
//
// Tips:
// - Para estilos basicos vá em: BASE.SCSS
// - Para palavras chaves, vá em VARIABLES.SCSS
// - Para animações, vá em ANIMATION.SCSS
// - Para componentes externos, vá em EXTERNAL-COMPONENTES.SCSS
@import 'CSE/variables';
$icon-font-path: '../../fonts/bootstrap/';
$fa-font-path: '../../fonts/font-awesome';
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import 'font-awesome/font-awesome.scss';
@import 'CSE/helpers';
@import 'CSE/base';
@import 'CSE/external-components';
@import 'CSE/header';
@import 'CSE/footer';
@import 'CSE/sign-in';
@import 'CSE/animations';
@import 'CSE/funel';
@import 'CSE/filter-advanced';
@import 'CSE/influencers-list';
@import 'CSE/modal';
@import 'CSE/profile';
@import 'CSE/alerts';
@import 'CSE/campaigns';
@import 'CSE/influencer-update';
@import 'CSE/plans';
