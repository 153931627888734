// PRIMENG 11

.coluna-plano .p-inputswitch {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent !important;
  cursor: pointer;

  .p-inputswitch-slider {
    visibility: hidden;
    cursor: pointer;
  }
}

.grid-of-plans {
  .feature-item {
    display: block;
    padding: 10px;
    border-bottom: 1px solid $cse-grey;
    margin: 0;
    height: 40px;
    label {
      font-size: 14px;
      margin: 0;
    }
    &:first-child {
      margin-top: 41px;
    }
    &.last {
      border: none;
      label {
        font-weight: bold;
      }
    }
  }
  .scroll-lateral {
    display: inline-block;
    height: auto;
    width: calc(100% - 270px);
    overflow-x: scroll;
    vertical-align: top;
    white-space: nowrap;
  }
}

.features-list {
  width: 260px;
  display: inline-block;
  vertical-align: top;
  margin-right: -5px;
}
.wrap-plano {
  vertical-align: top;
  display: inline-block;
  &.disable {
    .nome-plano {
      background: $cse-bluegrey;
    }
    .coluna-plano {
      border: 1px solid $cse-grey;
    }
  }
}
.coluna-plano {
  margin-right: 10px;
  border: 1px solid rgba(238, 117, 70, 0.2);
  border-radius: 8px;
  overflow: hidden;
  &.new-plan {
    width: 187px;
    border: 4px dashed $cse-blue;
    height: 751px;
    text-align: center;
    background: #fff;
    font-weight: bold;
    color: $cse-blue;
    text-transform: uppercase;
  }
  .ui-inputswitch {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }
}

.nome-plano {
  background: $cse-orange;
  color: #fff;
  font-weight: bold;
  height: 40px;
  width: 100%;
  padding: 10px;
  text-transform: uppercase;
  i {
    float: right;
  }
}
.toggle-line,
.input-line {
  padding: 10px;
  height: 40px;
  border-bottom: 1px solid $cse-grey;
  position: relative;
  .form-control {
    height: 20px;
    display: inline-block;
    padding: 6px 0;
    width: 80px;
    margin-left: 10px;
  }
  .form-control.free {
    margin-left: 30px;
  }
}

.toggle-line-item {
  &.active {
    color: $cse-green;
    font-weight: bold;
  }
  &.desligado {
    color: $cse-orange;
    font-weight: bold;
  }
  i {
    display: inline;
    margin-right: 10px;
  }
}

.unlimited {
  display: inline-flex;
  align-items: center;
  margin: 0 0 0 16px;
  line-height: 14px;
  font-size: 14px;
  input {
    margin: 0 3px 0 0;
  }
}

.no-value {
  color: transparent !important;
}
