.alert {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  .container {
    padding: 0;
    margin: 0;

    span {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 991px) {
    .close {
      span {
        font-size: 20px;
      }
    }
  }

  &::after {
    font-family: FontAwesome;
    font-size: 30px;
    margin-right: 15px;
  }

  &.alert-success::after {
    content: "\f058";
  }

  &.alert-info::after {
    content: "\f05a";
  }

  &.alert-warning::after {
    content: "\f06a";
  }

  &.alert-danger::after {
    content: "\f057";
  }

  &.alert-dismissable,
  &.alert-dismissible {
    padding-right: 15px;

    .close {
      position: relative;
      -webkit-transition: 0.6s;
      -o-transition: 0.6s;
      transition: 0.6s;
      margin-left: 10px;
      -ms-flex-negative: 0;
      flex-shrink: 0;
    }
  }

  //Posicionamento
  .auth-panel-log & {
    position: absolute;
    width: 91.2%;
    top: 10px;

    @media screen and (max-width: 768px) {
      position: relative;
      width: 100%;
      top: 0;
    }
  }

  .auth-panel-reg & {
    position: absolute;
    top: -350px;
    left: -672px;
    width: 296%;

    @media screen and (max-width: 991px) {
      position: relative;
      width: 100%;
      top: 0;
      left: 0;
      margin-top: 20px;
    }
  }

  .reg-externo & {
    position: relative;
    top: 0;
    left: 0;
    margin-top: -95px;
    width: 100%;

    @media screen and (max-width: 991px) {
      margin-top: 0;

      span {
        font-size: 14px;
      }
    }
  }

  #ladder-influence & {
    position: absolute;
    top: 65px;
    width: calc(100% - 110px);

    @media screen and (max-width: 991px) {
      position: relative;
      width: 100%;
      top: 0;
    }
  }

  #ladder-influence .modal-content &,
  .modal-content & {
    position: relative;
    top: -33px;
    width: calc(100% - 20px);
    margin: 10px;
  }

  .account-confirmation-alert & {
    position: fixed;
    bottom: 10px;
    width: calc(100% - 110px);

    &::after {
      content: "";
    }

    span {
      font-size: 20px;
    }

    @media screen and (max-width: 991px) {
      width: 100%;
      left: 0;
      bottom: 0;
    }
  }

  .auth-panel-log .redefine-pass & {
    position: relative;
    width: 100%;
    top: auto;
  }

  .added-to-campaign & {
    position: fixed;
    width: calc(100% - 110px);
    top: 80px;
    z-index: 10;
  }
}

.ui-growl {
  width: calc(100% - 80px);
  position: fixed;
  left: 40px;
  right: 40px;
  top: 80px;

  .ui-growl-container {
    padding: 0;
    margin: 0;
  }

  .ui-growl-item {
    color: #a94442;

  }

  .ui-state-highlight {
    background: #f2dede;
    border: 1px solid #ebccd1;
  }

  .ui-growl-icon-close {
    opacity: 0.2;
    margin-top: 20px;
    margin-right: 10px;
  }
}

/*** MEDIA QUERIES ***/

@media only screen and (max-width: $screen-md-max) {
  .added-to-campaign {
    .alert {
      width: calc(100% - 50px);
    }
  }
}

@media only screen and (max-width: $screen-xs-max) {
  .added-to-campaign {
    .alert {
      width: calc(100% - 30px);
    }
  }
}
